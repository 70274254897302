const dataShare = () => import('@/pages/dataShare/index.vue');
const publishData = () => import('@/pages/dataShare/publishData.vue');
const labShare = () => import('@/pages/dataShare/labShare.vue');
const weatherShare = () => import('@/pages/dataShare/weatherShare.vue');
const instrumentShare = () => import('@/pages/dataShare/instrumentShare.vue');
const instrumentDetaile = () => import('@/pages/dataShare/instrumentDetaile.vue');
const labDetaile = () => import('@/pages/dataShare/labDetaile.vue');
const baseDetail = () => import('@/pages/dataShare/baseDetail.vue');

export default [{
    path: '/dataShare',
    name: 'dataShare',
    component: dataShare,
    meta: {
        title: '共享发布' 
    }
}, {
    path: '/dataShare/publishData',
    name: 'publishData',
    component: publishData,
    meta: {
        title: '我要发布' 
    }
}, {
    path: '/dataShare/baseDetail',
    name: 'baseDetail',
    component: baseDetail,
    meta: {
        title: '基地详情' 
    }
}, {
    path: '/instrumentShare',
    name: 'instrumentShare',
    component: instrumentShare,
    meta: { 
        title: '仪器设备共享',
        notRequireAuthor: true 
    }
}, {
    path: '/labShare',
    name: 'labShare',
    component: labShare,
    meta: { 
        title: '实验室共享',
        notRequireAuthor: true 
    }
}, {
    path: '/weatherShare',
    name: 'weatherShare',
    component: weatherShare,
    meta: { 
        title: '气象共享',
        notRequireAuthor: true 
    }
},{
    path: '/instrumentShare/instrumentDetaile',
    name: 'instrumentDetaile',
    component: instrumentDetaile,
    meta: { 
        title: '仪器设备共享详情'
    }
}, {
    path: '/labShare/labDetaile',
    name: 'labDetaile',
    component: labDetaile,
    meta: { 
        title: '实验室共享详情'
    }
}];