// 转基因申报
const transgenicRegistration = () => import('@/pages/transgenicRegistration/index');
const registration = () => import('@/pages/transgenicRegistration/registration');
const approveRegistration = () => import('@/pages/transgenicRegistration/approveRegistration');

export default [
  {
    path: '/transgenicRegistration',
    name: 'transgenicRegistration',
    component: transgenicRegistration,
    meta: { title: '转基因申报' }
  },
  {
    path: '/registration',
    name: 'registration',
    component: registration,
    meta: { title: '转基因申报' }
  },
  {
    path: '/approveRegistration',
    name: 'approveRegistration',
    component: approveRegistration,
    meta: { title: '转基因申报' }
  }
];
