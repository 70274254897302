const coldChainCar = () => import('@/pages/market/coldChainCar.vue');
const SeedEnterprise = () => import('@/pages/market/SeedEnterprise.vue');
const SeedDetail = () => import('@/pages/market/SeedDetail.vue');
const recommendList = () => import('@/pages/market/recommendList.vue');
const coldChainCarDetails = () => import('@/pages/market/coldChainCarDetails.vue');
export default [{
  path: '/coldChainCar',
  name: 'coldChainCar',
  component: coldChainCar,
  meta: {
    title: '冷链车辆'
  }
}, {
  path: '/SeedEnterprise',
  name: 'SeedEnterprise',
  component: SeedEnterprise,
  meta: {
    title: '种子企业'
  }
}, {
  path: '/SeedDetail',
  name: 'SeedDetail',
  component: SeedDetail,
  meta: {
    title: '种子详情'
  }
}, {
  path: '/recommendList',
  name: 'recommendList',
  component: recommendList,
  meta: {
    title: '精选推荐'
  }
}, {
  path: '/coldChainCarDetails',
  name: 'coldChainCarDetails',
  component: coldChainCarDetails,
  meta: {
    title: '商品详情'
  }
}
]
;
