<template>
  <div id="app">
    <!-- 顶部 -->
    <div v-if="!meta.login">
      <home-header-top v-if="meta.isHome"></home-header-top>
      <header-top v-else></header-top>
    </div>

    <!-- 导航 -->
    <nav-top v-if="!meta.login&&!meta.isHome"></nav-top>
    <tips-Block v-if="!meta.login"></tips-Block>
    <!-- 主区域 -->
    <div class="index_content">
      <!-- <transition name="fade" enter-active-class="animated fadeIn"> -->
        <keep-alive :include="cache_components">
          <router-view :key="$route.fullPath"></router-view>
        </keep-alive>
      <!-- </transition> -->
    </div>
    <!-- 底部 -->
    <nav-bottom v-if="!meta.login"></nav-bottom>
  </div>
</template>

<script>
import headerTop from "@/pages/public/header-top.vue";
import homeHeaderTop from "@/pages/public/homeHeaderTop.vue";
import navTop from "@/pages/public/nav.vue";
import navBottom from "@/pages/public/bottom.vue";
import tipsBlock from "@/pages/public/tipsBlock.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["cache_components"]),
    meta() {
      return this.$route.meta;
    }
  },
  watch: {
    $route(val) {
      // 共享发布跳转到其他的页面不需要缓存 清除缓存
      // if (val.name !== 'downLoad' && val.name !== 'publishData') {
      //   this.$store.commit("removeCache", 'publishData');
      // }
      // if (val.name !== 'nanfanRecords') {
      //   this.$store.commit("removeCache", 'nanfanRecords');
      // }
      //滚动到顶部
      document.getElementById("app").scrollIntoView(true);
    }
  },
  components: {
    headerTop,
    navTop,
    navBottom,
    homeHeaderTop,
    tipsBlock
  }
};
</script>

<style lang="less" scoped>
#app {
  width: 100%;
  min-width: 1200px;
  .index_content{
    min-height: 650px;
  }
}
</style>
