const farmingMachinery = () => import('@/pages/market/farmingMachinery.vue');
const farmingMachineryDetail = () => import('@/pages/market/farmingMachineryDetail.vue');
const farmingMachineryMore = () => import('@/pages/market/farmingMachineryMore.vue');

export default [{
    path: '/farmingMachinery',
    name: 'farmingMachinery',
    component: farmingMachinery,
    meta: {
        title: '农机交易'
    }
}, {
    path: '/market/farmingMachineryDetail',
    name: 'farmingMachineryDetail',
    component: farmingMachineryDetail,
    meta: {
        title: '农机详情',
        notRequireAuthor: true
    }
}, {
    path: '/market/farmingMachineryMore',
    name: 'farmingMachineryMore',
    component: farmingMachineryMore,
    meta: {
        title: '农机交易',
        notRequireAuthor: true
    }
}
];