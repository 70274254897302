export default {
  singleSignOn: {
    api: '/api/member/open/v2/singleSignOn',
    name: '单点登录'
  },
  login: {
    api: '/api/member/open/v2/register',
    name: '用户注册并登录'
  },
  codeEnter: {
    api: '/api/member/open/ver-code',
    name: '短信验证码'
  },
  registerAccount: {
    api: '/api/member/open/registerPc',
    name: '账户注册'
  },
  resetPassword : {
    api: '/api/member/open/resetPasswordPc',
    name: '重置密码'
  },
  updatePassword : {
    api: '/api/member/backend/updatePassword',
    name: '修改密码'
  },
  memberInfoSynchronize : {
    api: '/api/member/memberInfoSynchronize',
    name: '用户与重点人员信息同步'
  }
};
