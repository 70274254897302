const pestData = () => import('@/pages/pestData/index.vue');
const pestDataDetail = () => import('@/pages/pestData/detail.vue');

export default [{
    path: '/pestData',
    name: 'pestData',
    component: pestData,
    meta: {
        title: '病害虫数据'
    }
}, {
    path: '/pestDataDetail',
    name: 'pestDataDetail',
    component: pestDataDetail,
    meta: {
        title: '病害虫数据详情'
    }
}];