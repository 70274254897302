<template>
  <div class="nav aaaa">
    <ul>
        <li v-for="(item, index) in navData" :key="index">
          <div
            @click="isSkip(v, index)"
            v-for="(v, i) in item"
            :key="i"
            :title="`点击进入${v.text}`"
            class="nav_item"
            :class='(index == navIndex && i == 0) ? "active" : " "'>
            <div v-if="$store.state.backlog != 0" class="display_fater">
              <div class="nav_item" v-if="v.text !== '移动办公' && v.text !== '业务协同'">{{v.text}}
                <dl v-if="v.level">
                      <dd @click.stop="levelClick(items)" v-for="(items, indexs) in v.level" :key="indexs">{{items.text}}</dd>
                    </dl>

                </div>
                <el-badge class="display_badge" v-if="(v.text === '业务协同' || v.text === '移动办公') && $store.state.backlog !== 0" :value="$store.state.backlog">
                <div class="display_class nav_item">{{v.text}}
                  <dl v-if="v.level">
                      <dd @click.stop="levelClick(items)" v-for="(items, indexs) in v.level" :key="indexs">{{items.text}}</dd>
                    </dl>

                </div>
              </el-badge>
            </div>
            <div v-else  class="nav_item">
             {{v.text}}
                <dl v-if="v.level">
                  <dd @click.stop="levelClick(items)" v-for="(items, indexs) in v.level" :key="indexs">{{items.text}}</dd>
                </dl>
            </div>
          </div>
        </li>
    </ul>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        backlog: 0,
        param: {
          circulateType: 2,
          documentStatus: 2,
          emergencyLevel: '',
          endDate: '',
          keyWord: '',
          pageNum: 1,
          pageSize: 10,
          startDate: ''
        },
        navData: [
          [
            {
              text: '首页',
              name: 'index'
            }
          ],
          [
            {
              text: '南繁大屏',
              name: 'nanFanDisplay',
              // path: 'http://nanfan.kechuang.cn/nanfan-display/baseSurvey.html'
               path: 'https://display.nfggy.com'
            }
          ],
          [
            {
              text: '南繁管理',
              name: 'nanFanManagement'
            }
          ],
          [
            {
              text: '南繁科研',
              name: ''
            },
            // {
            //   text: '金种子育种',
            //   // path: 'http://202.100.247.86:8091',
            //   path: 'http://101.201.226.232/',
            //   name: ''
            // },
            {
              text: '辅助决策',
              // path:
              //   'http://123.127.160.203:8089/decision/',
              path: 'https://fzjc.nfggy.com/decision',
              name: ''
            },
            {
              text: '遥感监测',
              // path: 'http:/123.127.160.70/hainan/index_cnout.html',
              // path: 'https://ygjc.nfggy.com/hainan/index.html',
              path: 'https://farmsense.bjtckj.cn/hainan/index.html',
              name: ''
            },
            {
              text: '数字博览馆',
              name: 'dataPavilion',
              path: 'https://nfzg.nfggy.com/zonghe'
            }
            // {
            //   text: '虚拟展厅',
            //   // path: path: 'http://101.201.102.60:18000/system/varNanfanIndex',
            //   path: 'http://202.100.247.86:18000/system/varNanfanIndex',
            //   name: ''
            // },
            // {
            //   text: '展厅管理',
            //   // path: 'http://101.201.102.60:18000/login',
            //   path: 'http://202.100.247.86:18000',
            //   name: ''
            // },
            // {
            //   text: '硅谷直通车',
            //   // path: 'http://101.201.102.60:18001/login',
            //   path: 'http://ztc.nfggy.com',
            //   name: ''
            // },
            // {
            //   text: '籽种大厅',
            //   // path: 'http://101.201.102.60:18002/login',
            //   path: 'http://202.100.247.86:18002',
            //   name: ''
            // },
            // {
            //   text: '南繁展馆',
            //   // path: ' https://720yun.com/t/64vkcli95pl?scene_id=59404153',
            //   path: 'http://202.100.247.86:18001/html/',
            //   name: ''
            // }
          ],
          [
            {
              text: '知识产权',
              name: ''
            },
            {
              text: '植物新品种',
              name: '',
              level: [
                {
                  text: '申请窗口',
                  path: 'https://zwfw.moa.gov.cn/',
                  name: ''
                },
                {
                  text: '查询窗口',
                  path: 'http://www.nybkjfzzx.cn/p_pzbh/sub_gg.aspx?n=21',
                  name: ''
                }
              ]
            },
            {
              text: '专利',
              name: '',
              level: [
                {
                  text: '专利检索',
                  name: 'patentSearch'
                },
                {
                  text: '专利评估',
                  name: 'patentAssess'
                },
                {
                  text: '专利维权',
                  name: 'patentRights'
                },
                {
                  text: '专利机器人',
                  name: 'patentRobot'
                }
              ]
            },
            {
              text: '商标',
              path: 'https://wsgg.sbj.cnipa.gov.cn:9080/tmann/annInfoView/homePage.html',
              name: ''
            },
            {
              text: '地理标志',
              path: 'http://www.cnipa.gov.cn/col/col2089/index.html',
              name: ''
            },
            {
              text: '基地成果',
              name: 'baseAchievement'
            },
            {
              text: '政策匹配',
              name: 'policyMatch'
            },

            // {
            //   text: '专利检索',
            //   name: 'patentSearch'
            // },
            // {
            //   text: '专利评估',
            //   name: 'patentAssess'
            // },
            // {
            //   text: '专利维权',
            //   name: 'patentRights'
            // },
            // {
            //   text: '专利机器人',
            //   name: 'patentRobot'
            // },
            // {
            //   text: '政策匹配',
            //   name: 'policyMatch'
            // }
          ],
          [
            {
              text: '资源共享',
              name: ''
            },
            {
              text: '仪器设备共享',
              name: 'instrumentShare'
            },
            {
              text: '实验室共享',
              name: 'labShare'
            },
            {
              text: '气象共享',
              name: 'weatherShare'
            },
            {
              text: '共享发布',
              name: 'dataShare'
            },
            {
              text: '南繁指数',
              // path: 'http://nanfan-index.idx365.com/website/#/data',
              path: 'https://zsmh.nfggy.com',
              name: ''
            },
          ],
          [
            {
              text: '南繁服务',
              name: 'publicServiceMap'
            }
            // {
            //   text: '法律服务',
            //   name: 'publicService',
            //   type: 1
            // },
            // {
            //   text: '咨询服务',
            //   name: 'publicService',
            //   type: 2
            // },
            // {
            //   text: '金融服务',
            //   name: 'publicService',
            //   type: 3
            // },
            // {
            //   text: '科创服务',
            //   name: 'publicService',
            //   type: 4
            // },
            // {
            //   text: '创业服务',
            //   name: 'publicService',
            //   type: 5
            // },
            // {
            //   text: '用工服务',
            //   name: 'employmentService'
            // }
          ],
          [
            {
              text: '专家人才',
              name: 'expertTalent'
            }
          ],
          [
            {
              text: '交易市场',
              name: ''
            },
            // {
            //   text: '化肥农药交易',
            //   name: 'agriculturalAssets',
            //   id: '1'
            // },
            // {
            //   text: '种质产权交易',
            //   name: 'germplasmDeal',
            //   id: '2'
            // },
            {
              text: '农机交易',
              name: 'farmingMachinery',
              id: '58058a421ea2ce524b92b1f81a439a9e'
            },
            {
              text: '土地租赁',
              name: 'landLeasing',
              id: 'c438df12cc4bd6626d3418978146562c'
            },
            {
              text: '冷链车辆',
              name: 'coldChainCar',
              id: '7842339ceb84ba2e66fa7876052bb545'
            },
            {
              text: '种子企业',
              name: 'SeedEnterprise'
              // id: '7842339ceb84ba2e66fa7876052bb545'
            }
          ],
          // [
          //   {
          //     text: '业务协同',
          //     name: ''
          //   },
          //   {
          //     text: '登记管理',
          //     name: 'nanfaninformatManage'
          //   },
          //   {
          //     text: '南繁用车',
          //     path: 'http://36.101.208.252:32568/gcyhpt/#/login',
          //     // name: 'nanfanCar/index'
          //     name: 'nanfanCar'
          //
          //   },
          //   {
          //     text: '移动办公',
          //     // name: 'officialDocument/awaitDocumentText'
          //     name: 'awaitDocumentText'
          //
          //   },
          //   {
          //     text: '勤务管理',
          //     // name: 'dutyManagement/approvalManagement'
          //     name: 'approvalManagement'
          //
          //   },
          //   {
          //     text: '开证申请',
          //     // name: 'issuingApply/upcomingApply'
          //     name: 'upcomingApply'
          //
          //   },
          //   {
          //     text: '重点项目',
          //     name: 'nanFanMajorList'
          //   }
          // ],
          [
            {
              text: '关于南繁',
              name: 'aboutNanfan'
            }
          ]
        ],
        navIndex: 0,
        isLeader: localStorage.getItem('idLeaderNb'),
        isNoNanfanPerson: localStorage.getItem('noPerson')
      };
    },
    mounted() {
      // this.queryReceiptList()
      if (this.isNoNanfanPerson === 'false') {
        this.navData.map((item, index) => {
          item.map((itm, idx) => {
            if (itm.text === '业务协同') {
              this.navData.splice(index, 1);
            }
          });
        });
      }

    },
    methods: {

      levelClick(v){
        window.SZHN_FIRE_BEAR?.trackEvent({
          key: "用户点击行为", // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
          segmentation: {
            用户点击行为: v.text,
          }
        })
        // alert(1)
        // console.log("v2222222",v)
        if(v.path){
          window.open(v.path);
        }else{
          this.$router.push({
            path: v.name,
            query: { type: v.type, id: v.id }
          });
        }
        const nowpath = this.$route.path.split('/');
        this.$router.push({
          path: v.name,
          query: { type: v.type, id: v.id }
        });
      },
      sort(index, i) {
        const item = this.navData[index].splice(i, 1)[0];
        this.navData[index].splice(0, 0, item);
      },
      isSkip(v, index) {
        window.SZHN_FIRE_BEAR?.trackEvent({
          key: "用户点击行为", // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
          segmentation: {
            用户点击行为: v.text,
          }
        })
        // alert(1222)
        console.log("id",v.id)
        console.log("path",v.path)
        console.log("name",v.name)
        if(v.path){
          window.open(v.path);
        }else{

          if(v.id && v.name){
            this.$router.push({ path: '/' + v.name, query: { type: v.type, id: v.id } });
          }else{
            this.$router.push({
              path: v.name,
            });
          }
        }
        // const nowpath = this.$route.path.split('/');
        // if (v.name) {
        //   this.navIndex = index;
        // }
        // if (!v.path) {
        //   if (!v.name) {
        //     return true;
        //   }
        //   if (v.name != nowpath[1]) {
        //     this.$router.push({ path: '/' + v.name, query: { type: v.type, id: v.id } });
        //   }
        // } else {
        //   window.open(v.path);
        //   return;
        // }
      },
      /**
       * 检索当前路径
       * @param path
       */
      checkRouterLocal(path) {
        // 查找当前路由下标高亮
        this.navData.forEach((item, index) => {
          item.forEach(itm => {
            if(itm.level){
              itm.level.forEach((items, indexs) => {
                if (path.indexOf(items.name) > 0) {
                  this.navIndex = index;
                }
              })
            }else{
              if (path.indexOf(itm.name) > 0) {
                this.navIndex = index;
              }
            }
          });
        });
      }
      // 查看待办数量
      // async queryReceiptList() {
      //   const api = this.$fetchApi.queryReceiptList.api;
      //   const data = await this.$fetchData.fetchPost(this.param, api, 'json');
      //   if (data.code === '200') {
      //     this.backlog = data.result.total;
      //   }
      // },
    },
    watch: {
      '$route': {
        handler() {
          // 获取当前路径
          const path = this.$route.path;
          // 检索当前路径
          this.checkRouterLocal(path);
        },
        immediate: true
      }
    }
  };
</script>
<style lang="less" scoped>
  .nav {
    width: 100%;
    height: 68px;
    background-color: #3380fe;
    z-index: 99;
    position: relative;

    ul {
      // width: 1200px;
      // height: 68px;
      // display: flex;
      // margin: 0 auto;
      // position: absolute;
      // left: 50%;
      // transform: translateX(-50%);

      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 95%;
      height: 68px;
      margin: 0 auto;

      li {
        z-index: 1000;
        overflow: hidden;
        width: 10%;
        height: 100%;
        transition: height 1s;
        float: left;
        background-color: #3380fe;
        .nav_item {
          height: 68px;
          width: 100%;
          line-height: 68px;
          color: #ffffff;
          font-family: MicrosoftYaHei;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          text-align: center;
          cursor: pointer;
          position: relative;


          &:hover {
              background-color: #8db1eb;
              dl{
                display: block;
              }
            }

            &:active {
              background: #568fec;
            }

            /deep/ .el-badge__content {
              position: absolute;
              top: .5rem;
              right: 1.2rem;
            }
        }


        .active {
          background: #8db1eb;
        }
        &:hover {
            height: auto;
            overflow: inherit;
            background-color: #6fa5fd;
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
         }
        dl{
          position: absolute;
          top:-16px;
          display: none;
          height: 68px;
          left:0;
        }
        dd {
          z-index: 1000;
          overflow: hidden;
          width: 120px;
          height: 100%;
          transition: height 1s;
          text-align: right;
          background-color:#8db1eb;
          text-align: center;
          margin:0 0 0 120px;
        }
      }
    }
  }

  /deep/ .el-badge__content {
    position: relative;
    top: -1rem;
    left: .5rem;
  }

  .display_fater {
    position: relative;
  }

  .display_class {
    position: absolute;
    width: 7.5rem;
    height: 4.25rem;
    left: -3rem;
  }

</style>
