const publicService = () => import('@/pages/publicService/index.vue');
const employmentService = () => import('@/pages/publicService/employmentService.vue');
const publicServiceDetail = () => import('@/pages/publicService/publicServiceDetail.vue');
const employmentDetail = () => import('@/pages/publicService/employmentDetail.vue');
const publicServiceMap = () => import('@/pages/publicService/publicServiceMap.vue');

export default [{
    path: '/publicService',
    name: 'publicService',
    component: publicService,
    meta: { 
        title: '南繁服务'
    }
}, {
    path: '/employmentService',
    name: 'employmentService',
    component: employmentService,
    meta: { 
        title: '用工服务'
    }
}, {
    path: '/publicService/publicServiceDetail',
    name: 'publicServiceDetail',
    component: publicServiceDetail,
    meta: { 
        title: '南繁服务详情'
    }
}, {
    path: '/publicService/employmentDetail',
    name: 'employmentDetail',
    component: employmentDetail,
    meta: { 
        title: '用工服务详情'
    }
},{
    path: '/publicServiceMap',
    name: 'publicServiceMap',
    component: publicServiceMap,
    meta: { 
        title: '南繁服务'
    }
}];