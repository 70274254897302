import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAMap from 'vue-amap'
import VueResource from 'vue-resource'
// import './plugins/element.js';
// import request from './apiConfig/fetchData';
// axios请求方法
import fetchData from '@/apiConfig/fetchData'
import fetchApi from '@/apiConfig/api'
// import echarts from 'echarts';
import * as echarts from 'echarts'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import configUtils from '@/utils/config'

import animate from 'animate.css'
Vue.use(animate)

// // 适配
// import 'amfe-flexible';
// import 'amfe-flexible/index.js';
// import '@/utils/rem.js'
// 顶部进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({
  easing: 'ease', // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3, // 初始化时的最小百分比
})
Vue.prototype.$echarts = echarts
Vue.prototype.$http = axios
Vue.use(ElementUI)
Vue.use(VueAMap)
Vue.use(VueResource)
// Vue.prototype.$request = request;
Vue.config.productionTip = false

/* eslint-disable */
Vue.$fetchData = Vue.prototype.$fetchData = fetchData // 绑定config
/* eslint-disable */
Vue.$fetchApi = Vue.prototype.$fetchApi = fetchApi // 绑定config

window.addEventListener(
  'message',
  function (e) {
    // console.log('msgGetToken: ' + e.data.token);
    const msgGetToken = e.data.token
    if (msgGetToken) {
      window.localStorage.setItem(configUtils.baseToken, msgGetToken)
    }
    e.source.postMessage('get', '*')
  },
  false
)
// ---------------------------------------- 路由钩子 ----------------------------------------
router.beforeEach(async (to, from, next) => {
  // 页面的title
  /**
   * 举例：

   * 1、判断是否登录页面
   * 2、是否登陆过
   * 2.1 、登录过并且检验token是否失效
   * 2.2、未登陆跳转到登陆页面
   */
  NProgress.start()
  // 获取token
  const token = window.localStorage.getItem(configUtils.baseToken)
  console.log('to.fullPath',to.fullPath);
  // 鉴于不需要登录是少数，不需要登录的组件路由meta加notRequireAuthor:true
  if (to.meta.notRequireAuthor) {
    // 首页跳转到其他页面进行账号认证
    next()
  } else {
    if (token) {
      if (from.name === 'index') {
        const api = fetchApi.roleConfirm.api
        const phone = JSON.parse(
          window.localStorage.getItem('loginaccount')
        ).phoneNumber
        const data = await fetchData.fetchPost(
          {
            phone,
          },
          api
        )
        if (data.code === '200') {
          if (!data.result) {
            return ElementUI.MessageBox.confirm(
              '是否进行账户认证?',
              '你还没有进行账户认证',
              {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              }
            )
              .then(() => {
                router.push({
                  name: 'downLoad',
                })
              })
              .catch(() => {})
          } else {
            next()
          }
        }
      } else {
        next()
      }
      next()
    } else {
      next({
        path: '/admin',
        query: {
          redirect: to.fullPath,
          index: 1,
        }, // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }
  }
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }
})
router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
})
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
