const germplasmResource = () => import('@/pages/germplasmResource/index.vue');
const germplasmDetail = () => import('@/pages/germplasmResource/detail.vue');
export default [{
    path: '/germplasmResource',
    name: 'germplasmResource',
    component: germplasmResource,
    meta: {
        title: '种质资源数据',
        notRequireAuthor: true
    }
}, {
    path: '/germplasmDetail',
    name: 'germplasmDetail',
    component: germplasmDetail,
    meta: {
        title: '种质资源数据详情',
        notRequireAuthor: true
    }
}];