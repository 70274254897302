const aboutNanfan = () => import('@/pages/aboutNanfan/index.vue');
const systemIntroduction = () => import('@/pages/aboutNanfan/systemIntroduction.vue');
const briefIntr = () => import('@/pages/aboutNanfan/briefIntr.vue');
const contactInfor = () => import('@/pages/aboutNanfan/contactInfor.vue');

export default [{
    path: '/aboutNanfan',
    name: 'aboutNanfan',
    component: aboutNanfan,
    meta: {
        title: '关于南繁',
        notRequireAuthor: true
    }
}, {
    path: '/aboutNanfan/systemIntroduction',
    name: 'systemIntroduction',
    component: systemIntroduction,
    meta: {
        title: '国家南繁管理体系简介',
        notRequireAuthor: true
    }
}, {
    path: '/aboutNanfan/briefIntr',
    name: 'briefIntr',
    component: briefIntr,
    meta: {
        title: '南繁简介及历程',
        notRequireAuthor: true
    }
}, {
    path: '/aboutNanfan/contactInfor',
    name: 'contactInfor',
    component: contactInfor,
    meta: {
        title: '国家南繁工作领导小组办公室（海南省南繁管理局）联系方式',
        notRequireAuthor: true
    }
}];