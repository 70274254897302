const api = {
  // 我的申请列表
  MyApplylist: {
    api: '/api/attendance/v1/approval/find-page',
    name: '我的申请列表'
  },
  getAttendanceDetail: {
    api: '/api/attendance/v1/approval/approver/',
    name: '審批連'
  },
  // 获取登录人信息 部门id
  getPersonInfo: {
    api: '/api/keypersonnel/findPersonnelByPhone',
    name: '获取登录人信息 部门id'
  },
  // 请假
  approvalapply: {
    api: '/api/attendance/v1/approval/apply',
    name: '获取登录人信息 部门id'
  },
  // 撤回
  revocation: {
    api: '/api/attendance/v1/approval/revocation',
    name: '获取登录人信息 部门id'
  },
  // 获取部门信息
  department: {
    api: '/api/attendance/v1/approval/department',
    name: '获取中台部门信息 部门id'
  },
  // 获取审批连
  approver: {
    api: '/api/attendance/v1/approval/approver/',
    name: '获取中台部门信息 部门id'
  },
  // 判断是否为局长办公室
  isLeader: {
    api: '/api/credential/v1/frontend/department',
    name: '判断是否为局长办公室'
  },
  // 查看是否为组织领导
  isNBleader: {
    api: '/api/attendance/v1/approval/organization-super-leader/',
    name: '查看是否为组织领导'
  },
  // 查看是否为南繁人员
  isNanfanPerson: {
    api: '/api/member/nanfan-bureau-member',
    name: '查看是否为组织领导'
  }
};
export default api;
