const laboratoryInfo = {
  queryMyList: {
    api: '/api/specimen/manage/query_my',
    name: '我的样品管理分页查询'
  },
  insertSample: {
    api: '/api/specimen/manage/insert',
    name: '样品管理新增'
  },
  exportSample: {
    api: '/api/specimen/manage/export',
    name: '样品管理报告生成'
  },
  checkSample: {
    api: '/api/specimen/manage/check',
    name: '样品管理分配检测人员'
  },
  queryDetail: {
    api: '/api/specimen/manage/query_detail/',
    name: '样品管理详细'
  },
  personnelList: {
    api: '/api/specimen/resource/personnelList',
    name: '分页查询人员信息列表'
  },
  addPersonnel: {
    api: '/api/specimen/resource/addPersonnel',
    name: '新增人员信息'
  },
  speFacilityList: {
    api: '/api/specimen/resource/facilityList',
    name: '分页查询设备信息列表'
  },
  addFacility: {
    api: '/api/specimen/resource/addFacility',
    name: '新增设备信息'
  },
  examiningListReq: {
    api: '/api/specimen/resource/examiningListReq',
    name: '分页查询检测报告列表'
  },
  downloadWord: {
    api: '/api/specimen/downloadWord/',
    name: '下载样品管理到word'
  },
  statistical: {
    api: '/api/specimen/statistical',
    name: '统计报表'
  },
  getSampledMemberList: {
    api: '/api/member/getSampledMemberList',
    name: '统计报表'
  },
  findMemberAuthByUserId: {
    api: '/api/member/findMemberAuthByUserId',
    name: '查询账号认证申请详情'
  }
};

export default laboratoryInfo;
