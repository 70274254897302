import axios from 'axios';
import qs from 'qs';
import configUtils from '@/utils/config';
import { message } from 'element-ui';
import { errorHandle } from './errorHandle';
// token获取
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

axios.interceptors.request.use(
  config => {
    // 登录流程控制中，根据本地是否存在token判断用户的登录情况
    // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
    // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
    // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作
    const getToken = window.localStorage.getItem(configUtils.baseToken);

    if (getToken) {
      const token = 'bearer ' + getToken;
      config.headers.Authorization = token;
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);
// 添加响应拦截器
axios.interceptors.response.use(
  // 请求成功
  res => {

    if (res.status === 200) {
      const code = res.data.code;
      if (code === '200') {
        Promise.resolve(res);
      } else {
        source.cancel();
        errorHandle(res.data.code, res.data.message);
      }
    } else {
      Promise.reject(res);
    }
    return res;
  },
  // 请求失败
  error => {
    const { response } = error;
    if (response) {
      // 请求已发出，但是不在2xx的范围
      errorHandle(response.status, response.data.message);
      return Promise.reject(response);
    } else {
      // 处理断网的情况
      message({
        message: "网络不正常",
        error: 'warning'
      });
    }

    return false;
  }
);

const options = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  timeout: 1000 * 12
};
const ajax = {
  /**
   * post请求方法
   * @param {param} 请求参数
   * @param {domainUrl} 域名
   */
  post: (param, domainUrl, type) => {
    const promise = new Promise((resolve, reject) => {
      let params = param;
      options.responseType = '';
      if (type === 'json') {
        options.headers['Content-Type'] = 'application/json';
      } else if (type === 'file') {
        options.headers['Content-Type'] = 'multipart/form-data';
      }  else if (type === 'arraybuffer') {
        options.responseType = 'arraybuffer';
      } else {
        options.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        params = qs.stringify(param);
      }
      axios
        .post(domainUrl, params, options)
        .then(res => {
          if (!res.data) {
            throw new Error('返回数据有误');
          }
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  },
  /**
   * get请求方法
   * @param {param} 请求参数
   * @param {domainUrl} 域名
   */
  get: (param, domainUrl) => {
    const promise = new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        params: param,
        baseURL: domainUrl,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then(response => {
          if (!response.data) {
            throw new Error('返回数据有误');
          }
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });

    return promise;
  }
};

export default ajax;
