/*
 * @Title: ****
 * @Date: 2020-10-27 09:15:49
 * @FilePath: /nanfan-portal/src/apiConfig/apis/bussiness.js
 */
const bussiness = {
  queryDispatchList: {
    api: '/api/official/backend/handlerEssay/queryDispatchList',
    name: '业务协同拟文/草稿箱列表'
  },
  queryReceiptList: {
    api: '/api/official/backend/handlerEssay/queryReceiptList',
    name: '业务协同待办列表'
  },
  queryAllReceiptList: {
    api: '/api/official/backend/handlerEssay/queryAllReceiptList',
    name: '业务协同收文列表'
  },
  selectNodeUser: {
    api: '/api/official/Backend/selectNodeUser',
    name: '下一节点审批人'
  },
  getDocumentCodeByCon: {
    api: '/api/official/getDocumentCodeByCon',
    name: '通过当前节点信息获取流转节点列表'
  },
  insertDocumentApply: {
    api: '/api/official/insertDocumentApply',
    name: '南繁办待办/公文呈批单-添加草稿'
  },
  getDispatchMemberList: {
    api: '/api/member/getDispatchMemberList',
    name: '业务协同查询发文对象'
  },
  sendDocumentApply: {
    api: '/api/official/sendDocumentApply',
    name: '公文呈批单-发送'
  },
  queryUserRank: {
    api: '/api/official/Backend/queryUserRank',
    name: '身份验证'
  },
  findApplyDetails: {
    api: '/api/official/findApplyDetails',
    name: '公文呈批单-发送'
  },
  updateReceiptStatus: {
    api: '/api/official/backend/handlerEssay/updateReceiptStatus',
    name: '撤回'
  },
  updateDocumentApply: {
    api: '/api/official/updateDocumentApply',
    name: '公文呈批单-修改草稿【仅草稿/驳回状态下才可编辑】'
  },
  queryApprovalChain: {
    api: '/api/official/Backend/queryApprovalChain/',
    name: '呈批单选择'
  },
  insertBatchFileInfo: {
    api: '/api/official/insertBatchFileInfo',
    name: '批量新增正文/附件'
  },
  deleteFileInfo: {
    api: '/api/official/deleteFileInfo/',
    name: '删除附件'
  },
  selectAllDocumentByPage: {
    api: '/api/official/selectAllDocumentByPage',
    name: '分页获取所有呈批单列表'
  },
  recallDocument: {
    api: '/api/official/recallDocument/',
    name: '发文列表-撤回公文'
  },
  updateStatus: {
    api: '/api/official/updateStatus',
    name: '修改呈批单状态为已删除'
  },
  newFile: {
    api: '/api/app/handlerEssay/newFile',
    name: '新建空白文件'
  },
  managerDocList:{
    api:'/api/official/managerDocList',
    name: '管理员-全部/已指派公文列表'
  },
  assignDoc:{
    api:'/api/official/assignDoc',
    name: '管理员-指派文件'
  },
  isAdministrators:{
    api:'/api/official/office-administrators',
    name: '是否是管理员'
  }
  
};

export default bussiness;
