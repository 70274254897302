<template>
  <div class="header-top">
    <div class="wrap">
      <div class="logo">
        <!-- <img src="../../../public/static/img/logoIndex.png" alt/> -->
         <img :src="require('@/assets/images/qrCode/logoIndex.png')" alt />
        <!-- <div class="wrap_text">
          <p>南繁硅谷云</p>
          <p>Nanfan Cloud</p>
        </div>-->
      </div>
      <div class="search">
        <p v-if="isLogin">
          <img :src="skycon||`${require('../../../public/static/img/weather/PARTLY_CLOUDY_DAY.png')}`" alt />
          {{`${temperature==undefined||temperature==''?'请开启定位':temperature+'℃'}`}}&nbsp;{{weather}}&nbsp;|&nbsp;{{windDirection}}{{windSpeed}}&nbsp;湿度{{humidity}}
          <span class="userNameClass">用户：{{userName.phoneNumber}}</span>
          <span
                  class="logout"
                  @click="dialogFormVisible = true"
          >修改密码</span>
          <span
                  class="logout"
                  @click="handleLogout"
          >退出登录</span>
        </p>
        <p v-else>
          <!-- <img :src="skycon" alt/> -->
          <!-- {{temperature}}℃&nbsp;{{weather}}&nbsp;|&nbsp;{{windDirection}}{{windSpeed}}&nbsp;湿度{{humidity}} -->
          <router-link
                  class="adminlogin"
                  :to="{
              name: 'adminlogin',
              query: {
                index: 1
              }
            }"
          >
            <span>您好！请登录</span>
          </router-link>
          <!-- <router-link
                  :to="{
              name: 'adminlogin',
              query: {
                index: 0
              }
            }"
          >注册
          </router-link> -->
        </p>
        <div class="nav">
          <ul>
            <li v-for="(item, index) in navData" :key="index" >
              <div
                @click.stop="isSkip(v)"
                v-for="(v, i) in item"
                :key="i"
                :title="`点击进入${v.text}`"
                class="nav_item"
              >
                <div v-if="backlog != 0">
                  <div class="nav_item" v-if="v.text !== '移动办公' && v.text !== '业务协同'">{{v.text}}
                    <dl v-if="v.level">
                      <dd @click.stop="levelClick(items)" v-for="(items, indexs) in v.level" :key="indexs">{{items.text}}</dd>
                    </dl>
                  </div>
                  <el-badge :value="backlog" class="item" v-if="(v.text == '业务协同' || v.text == '移动办公') && backlog !== 0">
                    <div class="nav_item">{{v.text}}
                        <dl v-if="v.level">
                          <dd @click.stop="levelClick(items)"  v-for="(items, indexs) in v.level" :key="indexs">{{items.text}}</dd>
                        </dl>
                    </div>
                  </el-badge>
                </div>
                <div v-else class="nav_item">
                  {{v.text}}
                  <dl v-if="v.level">
                      <dd @click.stop="levelClick(items)"  v-for="(items, indexs) in v.level" :key="indexs">{{items.text}}</dd>
                  </dl>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <el-amap :plugin="plugin" v-show="false"></el-amap>
    <el-dialog title="修改密码" :visible.sync="dialogFormVisible" width="30%">
      <el-form :model="form">
        <el-form-item label="ID" :label-width="formLabelWidth">
          <el-input v-model="form.id" autocomplete="off" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="用户名" :label-width="formLabelWidth">
          <el-input v-model="form.realName" autocomplete="off" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="新密码" :label-width="formLabelWidth">
          <el-input v-model="form.password" type="password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" :label-width="formLabelWidth">
          <el-input v-model="form.checkPassword" type="password" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleChangePassword">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import configUtils from '@/utils/config';
  import Vue from 'vue';
  import VueAMap from 'vue-amap';
  import {Loading, Message, MessageBox} from 'element-ui';

  VueAMap.initAMapApiLoader({
    key: 'ee80c1540f06a00d5a574ca6b4974422',
    plugin: [
      'AMap.Autocomplete',
      'AMap.PlaceSearch',
      'AMap.Scale',
      'AMap.OverView',
      'AMap.ToolBar',
      'AMap.MapType',
      'AMap.PolyEditor',
      'AMap.CircleEditor',
      'AMap.Geocoder'
    ],
    // 默认高德 sdk 版本为 1.4.4
    v: '1.4.4'
  });

  export default {
    data() {
      // eslint-disable-next-line consistent-this
      const self = this;
      return {
        input: '',
        isLeader: true,
        userName: '',
        backlog: 0,
        param: {
          circulateType: 2,
          documentStatus: 2,
          emergencyLevel: '',
          endDate: '',
          keyWord: '',
          pageNum: 1,
          pageSize: 10,
          startDate: ''
        },
        navData: [
          [
            {
              text: '首页',
              name: 'index'
            }
          ],
          [
            {
              text: '南繁大屏',
              name: 'namFanDisplay',
              // path: 'http://nanfan.kechuang.cn/nanfan-display/baseSurvey.html'
              path: 'https://display.nfggy.com'
            }
          ],
          [
            {
              text: '南繁管理',
              name: 'nanFanManagement'
            }
          ],
          [
            {
              text: '南繁科研',
              name: ''
            },
            // {
            //   text: '金种子育种',
            //   // path: 'http://202.100.247.86:8091',
            //   path: 'http://101.201.226.232/',
            //   name: ''
            // },
            {
              text: '辅助决策',
              // path: 'http://123.127.160.203:8089/decision/',
              path: 'https://fzjc.nfggy.com/decision',
              name: ''
            },
            {
              text: '遥感监测',
              // path: 'http://123.127.160.70/hainan/index_cnout.html',
              // path: 'https://ygjc.nfggy.com/hainan/index.html',
              path: 'https://farmsense.bjtckj.cn/hainan/index.html',
              name: ''
            },
            {
              text: '数字博览馆',
              name: 'dataPavilion',
              path: 'https://zzdt.nfggy.com/zonghe/index'
            }
            // {
            //   text: '虚拟展厅',
            //   // path: path: 'http://101.201.102.60:18000/system/varNanfanIndex',
            //   // path: 'http://202.100.247.86:18000/system/varNanfanIndex',
            //   path: 'http://xnzt.nfggy.com/system/varNanfanIndex',
            //   name: ''
            // },
            // {
            //   text: '展厅管理',
            //   // path: 'http://101.201.102.60:18000/login',
            //   // path: 'http://202.100.247.86:18000',
            //   path: 'http://xnzt.nfggy.com',
            //   name: ''
            // },
            // {
            //   text: '硅谷直通车',
            //   // path: 'http://101.201.102.60:18001/login',
            //   path: 'http://ztc.nfggy.com',
            //   name: ''
            // },
            // {
            //   text: '籽种大厅',
            //   // path: 'http://101.201.102.60:18002/login',
            //   // path: 'http://202.100.247.86:18002',
            //   path: 'http://zzdt.nfggy.com',
            //   name: ''
            // },
            // {
            //   text: '南繁展馆',
            //   // path: ' https://720yun.com/t/64vkcli95pl?scene_id=59404153',
            //   // path: 'http://202.100.247.86:18001/html/',
            //   path: 'http://nfzg.nfggy.com/html',
            //   name: ''
            // }
          ],
          [
            {
              text: '知识产权',
              name: ''
            },
            {
              text: '植物新品种',
              name: '',
              level: [
                {
                  text: '申请窗口',
                  path: 'https://zwfw.moa.gov.cn/',
                  name: ''
                },
                {
                  text: '查询窗口',
                  path: 'http://www.nybkjfzzx.cn/p_pzbh/sub_gg.aspx?n=21',
                  name: ''
                }
              ]
            },
            {
              text: '专利',
              name: '',
              level: [
                {
                  text: '专利检索',
                  name: 'patentSearch'
                },
                {
                  text: '专利评估',
                  name: 'patentAssess'
                },
                {
                  text: '专利维权',
                  name: 'patentRights'
                },
                {
                  text: '专利机器人',
                  name: 'patentRobot'
                }
              ]
            },
            {
              text: '商标',
              path: 'https://wsgg.sbj.cnipa.gov.cn:9080/tmann/annInfoView/homePage.html',
              name: ''
            },
            {
              text: '地理标志',
              path: 'http://www.cnipa.gov.cn/col/col2089/index.html',
              name: ''
            },
            {
              text: '基地成果',
              name: 'baseAchievement'
            },
            {
              text: '政策匹配',
              name: 'policyMatch'
            }
            // {
            //   text: '基地成果',
            //   name: 'baseAchievement'
            // },
            // {
            //   text: '专利检索',
            //   name: 'patentSearch'
            // },
            // {
            //   text: '专利评估',
            //   name: 'patentAssess'
            // },
            // {
            //   text: '专利维权',
            //   name: 'patentRights'
            // },
            // {
            //   text: '专利机器人',
            //   name: 'patentRobot'
            // },
            // {
            //   text: '政策匹配',
            //   name: 'policyMatch'
            // }
          ],
          [
            {
              text: '资源共享',
              name: ''
            },
            {
              text: '仪器设备共享',
              name: 'instrumentShare'
            },
            {
              text: '实验室共享',
              name: 'labShare'
            },
            {
              text: '气象共享',
              name: 'weatherShare'
            },
            {
              text: '共享发布',
              name: 'dataShare'
            },
            {
              text: '南繁指数',
              // path: 'http://nanfan-index.idx365.com/website/#/data',
              path: 'https://zsmh.nfggy.com',
              name: ''
            },
          ],
          [
            {
              text: '南繁服务',
              name: 'publicServiceMap'
            }
            // {
            //   text: "法律服务",
            //   name: "publicService",
            //   type: 1
            // },
            // {
            //   text: "咨询服务",
            //   name: "publicService",
            //   type: 2
            // },
            // {
            //   text: "金融服务",
            //   name: "publicService",
            //   type: 3
            // },
            // {
            //   text: "科创服务",
            //   name: "publicService",
            //   type: 4
            // },
            // {
            //   text: "创业服务",
            //   name: "publicService",
            //   type: 5
            // },
            // {
            //   text: "用工服务",
            //   name: "employmentService"
            // }
          ],
          [
            {
              text: '专家人才',
              name: 'expertTalent'
            }
          ],
          [
            {
              text: '交易市场',
              name: ''
            },
            // {
            //   text: '化肥农药交易',
            //   name: 'agriculturalAssets',
            //   id: '1'
            // },
            // {
            //   text: '种质产权交易',
            //   name: 'germplasmDeal',
            //   id: '2'
            // },
            {
              text: '农机交易',
              name: 'farmingMachinery',
              id: '58058a421ea2ce524b92b1f81a439a9e'
            },
            {
              text: '土地租赁',
              name: 'landLeasing',
              id: 'c438df12cc4bd6626d3418978146562c'
            },
            {
              text: '冷链车辆',
              name: 'coldChainCar',
              id: '7842339ceb84ba2e66fa7876052bb545'
            },
            {
              text: '种子企业',
              name: 'SeedEnterprise'
              // id: '7842339ceb84ba2e66fa7876052bb545'
            }
          ],
          // [
          //   {
          //     text: '业务协同',
          //     name: ''
          //   },
          //   {
          //     text: '登记管理',
          //     name: 'nanfaninformatManage'
          //   },
          //   {
          //     text: '南繁用车',
          //     path: 'http://36.101.208.252:32568/gcyhpt/#/login',
          //     // name: 'nanfanCar/index',
          //     name: 'nanfanCar'
          //   },
          //   {
          //     text: '移动办公',
          //     // name: 'officialDocument/awaitDocumentText',
          //     name: 'awaitDocumentText'
          //   },
          //   {
          //     text: '勤务管理',
          //     // name: 'dutyManagement/approvalManagement'

          //     name: 'approvalManagement'
          //   },
          //   {
          //     text: '开证申请',
          //     // name: 'issuingApply/upcomingApply'

          //     name: 'upcomingApply'
          //   },
          //   {
          //     text: '重点项目',
          //     name: 'nanFanMajorList'
          //   }
          // ],
          [
            {
              text: '关于南繁',
              name: 'aboutNanfan'
            }
          ]
        ],

        temperature: '',
        skycon: '',
        weather: '',
        windDirection: '',
        windSpeed: '',
        humidity: '',
        longitude: 0, // 定位点
        latitude: 0, // 定位点
        plugin: [
          // 获取当前位置
          {
            pName: 'Geolocation',
            events: {
              init(o) {
                // o 是高德地图定位插件实例
                o.getCurrentPosition((status, result) => {
                  // alert(result.formattedAddress);
                  // console.log(result);
                  if (result && result.position) {
                    self.longitude = result.position.lng;
                    self.latitude = result.position.lat;
                    self.getWeather();
                  }
                });
              }
            }
          }
        ],
        dialogFormVisible: false,
        form: {
          id: '',
          realName: '',
          password: '',
          checkPassword: '',
        },
        formLabelWidth: '120px'
      };
    },
    created(){
      const params = new URLSearchParams(window.location.search);
      const token = params.get('token'); // John
      if(token){
        this.loginWithToken(token);
      }
    },
    mounted() {
      this.queryReceiptList();
      this.getIsleader();
      this.userName = JSON.parse(window.localStorage.getItem('loginaccount'));
      this.form.realName = this.userName.phoneNumber;
      this.form.id = window.localStorage.getItem('accountUseerId');
    },
    methods: {
      async loginWithToken(token){
        // 加载中
        const loading = Loading.service({
          background: "rgba(0, 0, 0, .2)"
        });
        // 获取api发送请求
        const api = this.$fetchApi.singleSignOn.api;
        // const api = "http://192.168.3.8:36519/open/v2/register"
        const data = await this.$fetchData.fetchPost(
            {tokenString: token},
            api,
            "json"
        );
        if (data.code === "200" && data.result) {
          // 请求成功
          loading.close(); // 关闭加载
          // 状态保持,存储token
          window.localStorage.setItem(
              configUtils.baseToken,
              data.result.access_token,
          );
          window.localStorage.setItem(
              configUtils.kcCloudToken,
              data.result.jti,
          );
          window.localStorage.setItem(
              'loginaccount',
              JSON.stringify({
                phoneNumber: data.result.userInfo.username
              })
          );
          window.localStorage.setItem('accountUseerId', data.result.userInfo.id );
          window.SZHN_NATIVE_BEAR?.setUser(data.result.userInfo.id);
          window.SZHN_FIRE_BEAR?.setUser(data.result.userInfo.id);
          // 改变登录状态
          this.$store.commit("changeLoginStatus", true);
          Message.success("登录成功"); // 提示用户
          setTimeout(() => {
            this.redirect();
          }, 1000);
          const apis = this.$fetchApi.memberInfoSynchronize.api;
          await this.$fetchData.fetchPost({}, apis, "json");
        } else {
          if(data.message == "当前账号需要使用U盾登录") {
            let shebei = enumDevice();
            this.shebei = shebei;
            let userCert = getUserList()
            this.userCert = userCert;
            Message.error(data.message);
            this.UKeyShow = true;
          }
          loading.close(); // 关闭加载
          // Message.error(data.message); // 提示用户
        }
      },
      async handleChangePassword() {
        const api = this.$fetchApi.updatePassword.api;
        const data = await this.$fetchData.fetchPost(this.form,api,"json");
        if (data.code == "200") {
          this.$message.success("修改成功！");
          this.form.password="";
          this.form.checkPassword="";
          this.dialogFormVisible = false;
        }else{
          this.$message.error(data.message);
        }
      },
       handleLogout() {
        MessageBox.confirm('确定退出登录吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
             this.logout()


          })
          .catch(() => {});
      },
      async logout(){
        const api = this.$fetchApi.logout.api;
      const data = await this.$fetchData.fetchPost({},api);
            if (data.code == "200") {
                this.$store.commit('changeLoginStatus', false);
                window.localStorage.removeItem(configUtils.baseToken);
                window.localStorage.removeItem('loginaccount');
                this.$router.replace({
                  name: 'index'
                  // query: {
                  //   index: 1
                  // }
                });

            }else{

              this.$store.commit('changeLoginStatus', false);
                window.localStorage.removeItem(configUtils.baseToken);
                window.localStorage.removeItem('loginaccount');
                this.$router.replace({
                  name: 'index'
                  // query: {
                  //   index: 1
                  // }
                });
                this.$message.error(data.message);
            }

      },
      sort(index, i) {
        const item = this.navData[index].splice(i, 1)[0];
        this.navData[index].splice(0, 0, item);
      },
      levelClick(v){
        window.SZHN_FIRE_BEAR?.trackEvent({
          key: "用户点击行为", // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
          segmentation: {
            用户点击行为: v.text,
          }
        })
        // alert(2)
        console.log("v",v)
        if(v.path){
          window.open(v.path);
        }else{
          this.$router.push({
            path: v.name,
            query: { type: v.type, id: v.id }
          });
        }
        const nowpath = this.$route.path.split('/');
        this.$router.push({
          path: v.name,
          query: { type: v.type, id: v.id }
        });
      },
      isSkip(v) {
        console.log(v)
        window.SZHN_FIRE_BEAR?.trackEvent({
          key: "用户点击行为", // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
          segmentation: {
            用户点击行为: v.text,
          }
        })
        const nowpath = this.$route.path.split('/');
        if (!v.path) {
          if (!v.name) {
            return true;
          }
          if (v.name !== nowpath[1]) {
            this.$router.push({
              path: v.name,
              query: { type: v.type, id: v.id }
            });
          }
        } else {
          if (v.name === 'dataPavilion'){
             const token = window.localStorage.getItem(configUtils.baseToken);
             const path = token?`${v.path}?token=${token}`:v.path;
             return  window.open(path);
          }
          window.open(v.path);
        }
      },
      getWeather() {
        // 获取天气信息
        this.$http
          .jsonp(
            'https://api.caiyunapp.com/v2/IqOWcp5rOU5EaN7f/' +
            this.longitude +
            ',' +
            this.latitude +
            '/realtime.jsonp',
            { jsonp: 'callback' }
          )
          .then(res => {



            const nowData = res.body.result;
            // console.log(this.longitude, this.latitude, nowData);
            this.getWeatherIcon(nowData.skycon);
            this.temperature = nowData.temperature;
            this.weather = this.changeWeather(nowData.skycon);
            this.windDirection = this.changePosition(nowData.wind.direction);
            this.windSpeed = this.changeWindSpeed(nowData.wind.speed);
            this.humidity = Math.round(nowData.humidity * 100) + '%';
          })
          .catch(res => {
            console.log(res);
          });
      },
      getWeatherIcon(val) {
        //获取天气图标
        switch (val) {
          case val:
            this.skycon = require('../../../public/static/img/weather/' +
              val +
              '.png');
            break;
        }
      },
      changeWeather(val) {
        switch (val) {
          case 'CLEAR_DAY':
            return '晴朗';
            break;
          case 'CLEAR_NIGHT':
            return '晴朗';
            break;
          case 'PARTLY_CLOUDY_DAY':
            return '多云';
            break;
          case 'PARTLY_CLOUDY_NIGHT':
            return '多云';
            break;
          case 'CLOUDY':
            return '阴';
            break;
          case 'RAIN':
            return '雨';
            break;
          case 'SNOW':
            return '雪';
            break;
          case 'WIND':
            return '风';
            break;
          case 'HAZE':
            return '雾霾沙尘';
            break;
        }
      },
      changePosition(val) {
        //角度转化方位
        if (val > 11.26 && val < 78.75) {
          return '东北风';
        } else if (val > 78.76 && val < 101.25) {
          return '东风';
        } else if (val > 101.26 && val < 168.75) {
          return '东南风';
        } else if (val > 168.76 && val < 191.25) {
          return '南风';
        } else if (val > 191.26 && val < 258.75) {
          return '西南风';
        } else if (val > 258.76 && val < 281.25) {
          return '西风';
        } else if (val > 281.26 && val < 348.75) {
          return '西北风';
        } else if (val > 348.7 && val <= 360) {
          return '北风';
        } else if (val >= 0 && val < 11.25) {
          return '北风';
        }
      },
      changeWindSpeed(val) {
        //风力等级对照
        if (val > 0 && val < 0.2) {
          return '0级';
        } else if (val > 0.3 && val < 1.5) {
          return '1级';
        } else if (val > 1.6 && val < 3.3) {
          return '2级';
        } else if (val > 3.4 && val < 5.4) {
          return '3级';
        } else if (val > 5.5 && val < 7.9) {
          return '4级';
        } else if (val > 8 && val < 10.7) {
          return '5级';
        } else if (val > 10.8 && val < 13.8) {
          return '6级';
        } else if (val > 13.9 && val < 17.1) {
          return '7级';
        } else if (val > 17.2 && val < 20.7) {
          return '8级';
        } else if (val > 20.8 && val < 24.4) {
          return '9级';
        } else if (val > 24.5 && val < 28.4) {
          return '10级';
        } else if (val > 28.5 && val < 32.6) {
          return '11级';
        } else if (val > 32.7 && val < 36.9) {
          return '12级';
        } else if (val > 37 && val < 41.4) {
          return '13级';
        } else if (val > 41.5 && val < 46.1) {
          return '14级';
        } else if (val > 46.2 && val < 50.9) {
          return '15级';
        } else if (val > 51 && val < 56) {
          return '16级';
        } else if (val > 56.1 && val < 61.2) {
          return '17级';
        } else if (val >= 61.3) {
          return '17级以上';
        }
      },

      async getIsleader() {
        const data =  await this.$fetchData.fetchGet({}, this.$fetchApi.isNanfanPerson.api, 'json'); // 查看是否为南繁人员

        try {
          if (data.result === true) {
            // 南繁人员
            localStorage.setItem('noPerson', true);
          } else {
            //  非南繁人员
            this.navData.map((item, index) => {
              item.map((itm, idx) => {
                if (itm.text === '业务协同') {
                  this.navData.splice(index, 1);
                }
              });
            });
            // 删除南繁办公
            localStorage.setItem('noPerson', false);
          }
        } catch (e) {
          console.log(e, '错误');
        }
      },
      // 查看待办数量
      async queryReceiptList() {
        const api = this.$fetchApi.queryReceiptList.api;
        const data = await this.$fetchData.fetchPost(this.param, api, 'json');
        if (data.code === '200') {
          this.backlog = data.result.total;
        }
      }
    },
    watch: {
    },
    //
    // this.navData.forEach((item, index) => {
    //   console.log(item, 'this.navData222');
    //   item.forEach((itm, idx) => {
    //     if (itm.text === '开证申请') {
    //       console.log(itm);
    //       delete this.navData[index][idx];
    //     }
    //   });
    // });
    // console.log(this.navData, 'this.navData');
    //
    computed: {
      isLogin() {
        return this.$store.state.isLogin;
      }
    }
  };
</script>
<style lang="less">
  .header-top {
    width: 100%;
    height: 98px;
    background-color: #3380fe;
    font-size: 0.09375rem;
    .wrap {
      width: 97%;
      height: 100%;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 5px 20px;
      justify-content: space-between;
      align-items: center;

      .logo {
        width: 250px;
        height: 100%;
        float: left;

        img {
          margin: 13px 0;
        }

        .wrap_text {
          p {
            margin: 5px 0;
            text-indent: 15px;
            color: #fff;
          }

          p:nth-child(1) {
            font-size: 32px;
          }

          p:nth-child(2) {
            font-size: 24px;
          }
        }
      }

      .search {
        width: 82%;
        height: 100%;
        float: right;

        .logout:hover {
          color: #b1cdfa;
        }

        p {
          width: 100%;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #333333;
          text-align: right;
          color: #b1cdfa;
          line-height: 40px;
          position: relative;

          .adminlogin {
            margin: 0 20px;
            display: inline-block;
          }

          a:hover {
            color: #b1cdfa;
          }

          img {
            width: 30px;
            height: 30px;
            transform: translateY(20%);
          }

          span {
            padding: 0 10px;
            cursor: pointer;
          }
        }
      }
    }

    .nav {
      z-index: 99;
      position: relative;
      height: 50%;

      ul {
        position: absolute;
        display: flex;
         //align-items: flex-start;
         justify-content: space-between;
        height: 48px;
        width: 100%;
        li {
          float: left;
          z-index: 1000;
          overflow: hidden;
          width: 90px;
          height: 100%;
          transition: height 1s;
          text-align: right;
          background-color: #3380fe;
          text-align: center;

          .nav_item {
            line-height: 48px;
            color: #ffffff;
            font-family: MicrosoftYaHei;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            cursor: pointer;
            background: #6fa5fd;
            position: relative;

            &:hover {
              background-color: #8db1eb;
              dl{
                display: block;
              }
            }

            &:active {
              background: #568fec;
            }

            /deep/ .el-badge__content {
              position: absolute;
              top: .5rem;
              right: 1.2rem;
            }
            // .nav_item{
            //   dl{
            //     display: block;
            //   }
            // }
          }
          .nav_item:first-child .nav_item{
              background: #3380fe !important;
          }
          &:hover {
            height: auto;
            overflow: inherit;
            background-color: #6fa5fd;
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
          }


        dl{
          position: absolute;
          top:-16px;
          display: none;
          height: 48px;
          left:0;

        }
        dd {
          z-index: 1000;
          overflow: hidden;
          width: 90px;
          height: 100%;
          transition: height 1s;
          text-align: right;
          // background-color: #3380fe;
          background-color:#8db1eb;
          text-align: center;
          margin:0 0 0 90px;
        }
        }
      }
    }
  }

</style>
