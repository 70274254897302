const landLeasing = () => import('@/pages/market/landLeasing.vue');
const landLeasingDetail = () => import('@/pages/market/landLeasingDetail.vue');
const landLeasingMore = () => import('@/pages/market/landLeasingMore.vue');
export default [{
    path: '/landLeasing',
    name: 'landLeasing',
    component: landLeasing,
    meta: {
        title: '土地租赁'
    }
}, 
{
    path: '/market/landLeasingDetail',
    name: 'landLeasingDetail',
    component: landLeasingDetail,
    meta: {
        title: '土地租赁详情'
    }
},

{
    path: '/market/landLeasingMore',
    name: 'landLeasingMore',
    component: landLeasingMore,
    meta: {
        title: '土地租赁'
    }
}];