// 成果转化
export default {
  achievementList: {
    api: '/api/property/query-achievement-list',
    name: '成果列表'
  },
  demandList: {
    api: '/api/property/query-demand-list',
    name: '需求列表'
  },
  achievementDetail: {
    api: '/api/property/open/query-achievement-details/',
    name: '成果详情'
  },
  demandDetail: {
    api: '/api/property/open/query-demand-details/',
    name: '需求详情'
  },
  leaveList: {
    api: '/api/property/query-leave-words',
    name: '留言列表'
  }
};
