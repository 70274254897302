export default {
    /** ********** 动态新闻 - 模块接口 ********** */
    dynamicNewsList: {
        api: '/api/information/open/query-information/list',
        name: '动态新闻 - 列表'
    },
    dynamicNewsDetail: {
        api: '/api/information/open/query-information-detail',
        name: '动态新闻 - 详情'
    },
    homeBanner: {
        api: '/api/banner/open/query-banner'
    },
    pcNewsList: {
        api: '/api/information/open/pc/query-information/list',
        name: '动态新闻 - 列表'
    }, 
    getNewsKeyWords: {
        api: '/api/information/open/pc/query-keywords',
        name: '搜索关键词'
    },
    getSearchNews: {
        api: '/api/publicsentiment/open/find/information/page',
        name: '搜索新闻'
    },
    logout: {
        api: '/api/member/logout',
        name: '退出登录'
    },
};