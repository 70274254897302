const nanfanCarApis = {
  /** ********** 南繁用车 - 模块接口 ********** */
  nanFanCarGetApplyList: {
    api: '/api/usingcar/backend/car/apply/list',
    name: '南繁用车 - 申请列表'
  },
  nanFanCarApply: {
    api: '/api/usingcar/backend/car/apply',
    name: '南繁用车 - 申请'
  },
  nanFanCarApprover:{
    // api: '/api/usingcar/backend/car/approver',
    api: '/api/usingcar/backend/car/v2/approver', //新需求更换接口
    name: '南繁用车 - 审批链'
  },
  nanFanCarApplyDetail: {
    api: '/api/usingcar/backend/car/apply/detail',
    name: '南繁用车 - 申请详情'
  }, 
  nanFanCarApplyCancel: {
    api: '/api/usingcar/backend/car/cancel-car-use',
    name: '南繁用车 - 取消申请'
  },
  nanFanCarisAdopt: {
    api: '/api/usingcar/backend/car/isAdopt',
    name: '南繁用车 - 判断用户权限'
  },
  nanFanCarisApproval: {
    api: '/api/usingcar/backend/car/isApproval',
    name: '南繁用车 - 审批人'
  },
  nanFanCarisReturnCar: {
    api: '/api/usingcar/backend/car/isReturnCar',
    name: '南繁用车 - 派车人'
  },
  nanfanCarApprovalerList:{
    api: '/api/usingcar/backend/car/approvaler/list',
    name: '南繁用车 - 审批人列表'
  },
  nanfanCarApprovalerALlList:{
    api: '/api/usingcar/backend/car/approvaler/all/list',
    name: '南繁用车 - 审批人全部列表'
  },
  nanfanCarSenderList:{
    api: '/api/usingcar/backend/car/sender/list',
    name: '南繁用车 - 派车人列表'
  },
  nanfanCarSenderAllList:{
    api: '/api/usingcar/backend/car/sender/all/list',
    name: '南繁用车 - 派车人全部列表'
  },
  nanfanCarApproval:{
    api: '/api/usingcar/backend/car/approval',
    name: '南繁用车 - 审核'
  },
  nanfanCarSendCars:{
    api: '/api/usingcar/backend/car/sendCars',
    name: '南繁用车 - 派车'
  },
  nanfanCarStatisticsList:{
    api:'/api/usingcar/backend/car/statistics/list',
    name: '南繁用车 - 统计列表'
  },
  nanfanCarUseStatistics:{
    api:'/api/usingcar/backend/car/useStatistics',
    name: '南繁用车 - 统计详情'
  },
  nanFanCarReturnCar:{
    api:'/api/usingcar/backend/car/return-the-car',
    name: '南繁用车 - 还车'
  },
  nanFanCarExportExpress:{
    api:'/api/usingcar/backend/car/exportExpress',
    name: '南繁用车 - 导出表格'
  },
  nanFanCarManageList:{
    api:'/api/usingcar/backend/car/manage/list',
    name: '南繁用车 - 待处理申请'
  },
  nanFanCarManageAllList:{
    api:'/api/usingcar/backend/car/manage/all/list',
    name: '南繁用车 - 全部申请'
  } 
};

export default nanfanCarApis;
