<template>
  <div class="header-top">
    <div class="wrap">
      <div class="logo">
        <!-- <img src="../../../public/static/img/logo.png" alt /> -->
         <img :src="require('@/assets/images/qrCode/logo.png')" alt />
         <!-- <img :src="require('@/assets/images/qrCode/logoIndex.png')" alt /> -->
        <!-- <div class="wrap_text">
          <p>南繁硅谷云</p>
          <p>Nanfan Cloud</p>
        </div>-->
      </div>
      <div class="search">
        <div class="search_input" style="margin-top: 20px;">
          <!-- <div class="left_search">
            <el-input v-model="input"></el-input>
          </div>
          <div class="search_icon">
            <img
              title="点击搜索"
              src="http://hnnmy.oss-cn-shanghai.aliyuncs.com/file/demand/static/2019-11-01/1e061c1e-6b7f-4161-8c95-ad83c90ac6ab.png"
              alt
            />
          </div>-->
        </div>
      </div>
      <div class="search">
        <p>
          <!-- <img :src="skycon" alt />
          {{temperature}}℃&nbsp;{{weather}}&nbsp;|&nbsp;{{windDirection}}{{windSpeed}}&nbsp;湿度{{humidity}} -->
        </p>

        <p v-if="isLogin" class="signOut">
          <span class="userNameClass">用户：{{userName.phoneNumber}}</span>
          <span @click="handleLogout">退出登录</span>
          </p>
        <p v-else>
          <router-link
            :to="{
              name: 'adminlogin',
              query: {
                index: 1
              }
            }"
          >您好！&nbsp;登录&nbsp;</router-link>
          <!-- <router-link
            :to="{
              name: 'adminlogin',
              query: {
                index: 0
              }
            }"
          >注册</router-link> -->
        </p>
      </div>
    </div>
    <el-amap :plugin="plugin" v-show="false"></el-amap>
  </div>
</template>
<script>
import configUtils from "@/utils/config";
import VueAMap from "vue-amap";
import { MessageBox } from "element-ui";

VueAMap.initAMapApiLoader({
  key: "ee80c1540f06a00d5a574ca6b4974422",
  plugin: [
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.PolyEditor",
    "AMap.CircleEditor",
    "AMap.Geocoder"
  ],
  // 默认高德 sdk 版本为 1.4.4
  v: "1.4.4"
});

export default {
  data() {
    const self = this;
    return {
      input: "",
      temperature: "",
      skycon: "",
      weather: "",
      windDirection: "",
      windSpeed: "",
      humidity: "",
      longitude: 0, // 定位点
      latitude: 0, // 定位点
      userName: '',
      plugin: [
        // 获取当前位置
        {
          pName: "Geolocation",
          events: {
            init(o) {
              // o 是高德地图定位插件实例
              o.getCurrentPosition((status, result) => {
                // alert(result.formattedAddress);
                // console.log(result);
                if (result && result.position) {
                  self.longitude = result.position.lng;
                  self.latitude = result.position.lat;
                  self.getWeather();
                }
              });
            }
          }
        }
      ]
    };
  },
  mounted() {
    this.userName = JSON.parse(window.localStorage.getItem('loginaccount'));
  },
  methods: {

    handleLogout() {
      MessageBox.confirm("确定退出登录吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {

          this.logout()


        })
        .catch(() => {});
    },
    async logout(){
      const api = this.$fetchApi.logout.api;
      const data = await this.$fetchData.fetchPost({},api);
      if (data.code == "200") {
          this.$store.commit('changeLoginStatus', false);
          window.localStorage.removeItem(configUtils.baseToken);
          window.localStorage.removeItem('loginaccount');
          this.$router.replace({
            name: 'index'
            // query: {
            //   index: 1
            // }
          });

      }else{
          this.$message.error(data.message);
      }

    },
    getWeather() {
      // 获取天气信息
      this.$http
        .jsonp(
          "https://api.caiyunapp.com/v2/IqOWcp5rOU5EaN7f/" +
            this.longitude +
            "," +
            this.latitude +
            "/realtime.jsonp",
          { jsonp: "callback" }
        )
        .then(res => {
          const nowData = res.body.result;
          // console.log(this.longitude, this.latitude, nowData);
          this.getWeatherIcon(nowData.skycon);
          this.temperature = nowData.temperature;
          this.weather = this.changeWeather(nowData.skycon);
          this.windDirection = this.changePosition(nowData.wind.direction);
          this.windSpeed = this.changeWindSpeed(nowData.wind.speed);
          this.humidity = Math.round(nowData.humidity * 100) + "%";
        })
        .catch(res => {
          console.log(res);
        });
    },
    getWeatherIcon(val) {
      //获取天气图标
      switch (val) {
        case val:
          this.skycon = require("../../../public/static/img/weather/" +
            val +
            ".png");
          break;
      }
    },
    changeWeather(val) {
      switch (val) {
        case "CLEAR_DAY":
          return "晴朗";
          break;
        case "CLEAR_NIGHT":
          return "晴朗";
          break;
        case "PARTLY_CLOUDY_DAY":
          return "多云";
          break;
        case "PARTLY_CLOUDY_NIGHT":
          return "多云";
          break;
        case "CLOUDY":
          return "阴";
          break;
        case "RAIN":
          return "雨";
          break;
        case "SNOW":
          return "雪";
          break;
        case "WIND":
          return "风";
          break;
        case "HAZE":
          return "雾霾沙尘";
          break;
      }
    },
    changePosition(val) {
      //角度转化方位
      if (val > 11.26 && val < 78.75) {
        return "东北风";
      } else if (val > 78.76 && val < 101.25) {
        return "东风";
      } else if (val > 101.26 && val < 168.75) {
        return "东南风";
      } else if (val > 168.76 && val < 191.25) {
        return "南风";
      } else if (val > 191.26 && val < 258.75) {
        return "西南风";
      } else if (val > 258.76 && val < 281.25) {
        return "西风";
      } else if (val > 281.26 && val < 348.75) {
        return "西北风";
      } else if (val > 348.7 && val <= 360) {
        return "北风";
      } else if (val >= 0 && val < 11.25) {
        return "北风";
      }
    },
    changeWindSpeed(val) {
      //风力等级对照
      if (val > 0 && val < 0.2) {
        return "0级";
      } else if (val > 0.3 && val < 1.5) {
        return "1级";
      } else if (val > 1.6 && val < 3.3) {
        return "2级";
      } else if (val > 3.4 && val < 5.4) {
        return "3级";
      } else if (val > 5.5 && val < 7.9) {
        return "4级";
      } else if (val > 8 && val < 10.7) {
        return "5级";
      } else if (val > 10.8 && val < 13.8) {
        return "6级";
      } else if (val > 13.9 && val < 17.1) {
        return "7级";
      } else if (val > 17.2 && val < 20.7) {
        return "8级";
      } else if (val > 20.8 && val < 24.4) {
        return "9级";
      } else if (val > 24.5 && val < 28.4) {
        return "10级";
      } else if (val > 28.5 && val < 32.6) {
        return "11级";
      } else if (val > 32.7 && val < 36.9) {
        return "12级";
      } else if (val > 37 && val < 41.4) {
        return "13级";
      } else if (val > 41.5 && val < 46.1) {
        return "14级";
      } else if (val > 46.2 && val < 50.9) {
        return "15级";
      } else if (val > 51 && val < 56) {
        return "16级";
      } else if (val > 56.1 && val < 61.2) {
        return "17级";
      } else if (val >= 61.3) {
        return "17级以上";
      }
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin;
    }
  }
};
</script>
<style lang="less" scoped>
.header-top {
  width: 100%;
  height: 98px;
  background-color: #ffffff;
  .wrap {
    width: 95%;
    height: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 5px 20px;
    justify-content: space-between;
    align-items: center;
    .logo {
      width: 33.33%;
      height: 100%;
      float: left;
      img {
        margin: 13px 0;
      }
      .wrap_text {
        p {
          margin: 5px 0;
          text-indent: 15px;
        }
        p:nth-child(1) {
          font-family: MicrosoftYaHei-Bold;
          font-size: 32px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 1px;
          color: #265ae1;
        }
        p:nth-child(2) {
          font-family: MicrosoftYaHei;
          font-size: 24px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #265ae1;
        }
      }
    }
    .search {
      width: 33.33%;
      height: 100%;
      float: left;
      .signOut {
        cursor: pointer;
      }

      .userNameClass {
        margin-right: 30px;
      }
      p {
        width: 100%;
        font-family: MicrosoftYaHei;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #333333;
        text-align: right;
        color: #448afc;
        line-height: 40px;
        position: relative;
        a {
          &:hover {
            color: #448afc;
          }
        }
        img {
          width: 30px;
          height: 30px;
          transform: translateY(20%);
        }
      }
      .search_input {
        .left_search {
          float: left;
        }
        .search_icon {
          margin-left: -2px;
          width: 40px;
          height: 40px;
          background-color: #448afc;
          border-radius: 0px 5px 5px 0px;
          z-index: 100;
          cursor: pointer;
          text-align: center;
          line-height: 35px;
          img {
            width: 24px;
            height: 24px;
            vertical-align: middle;
            transition: 0.5s;
          }
          &:hover img {
            transform: scale(1.15);
          }
          // background:url('http://hnnmy.oss-cn-shanghai.aliyuncs.com/file/demand/static/2019-11-01/1e061c1e-6b7f-4161-8c95-ad83c90ac6ab.png') no-repeat center center / 60% 60% #448afc
        }
      }
    }
  }
}
</style>
