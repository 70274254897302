const nanFanManagement = () => import('@/pages/nanFanManagement/index.vue');
const nanFanMajorList = () => import('@/pages/nanFanManagement/nanFanMajorList.vue');
const nanFanMajorDetails = () => import('@/pages/nanFanManagement/nanFanMajorDetails.vue');
const Biological = () => import('@/pages/nanFanManagement/Biological.vue');
export default [{
    path: '/nanFanManagement',
    name: 'nanFanManagement',
    component: nanFanManagement,
    meta: {
        title: '南繁管理'
    }
},
{
    path: '/nanFanMajorList',
    name: 'nanFanMajorList',
    component: nanFanMajorList,
    meta: { title: '重点项目列表' }
},
{
    path: '/Biological',
    name: 'Biological',
    component: Biological,
    meta: { title: '生物监控' }
},
{
    path: '/nanFanMajorDetails',
    name: 'nanFanMajorDetails',
    component: nanFanMajorDetails,
    meta: { title: '重点项目详情' }
}];