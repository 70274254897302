const expertTalent = () => import('@/pages/expertTalent/expertTalent');
const expertTalentDetail = () =>
  import('@/pages/expertTalent/expertTalentDetail');

export default [
  {
    path: '/expertTalent',
    name: 'expertTalent',
    component: expertTalent,
    meta: { title: '专家人才' }
  },
  {
    path: '/expertTalentDetail',
    name: 'expertTalentDetail',
    component: expertTalentDetail,
    meta: { title: '专家人才详情' }
  }
];
