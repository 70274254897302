const machineSubsidy = () => import('@/pages/machineSubsidy/index.vue');
const machineSubsidyDetail = () => import('@/pages/machineSubsidy/detail.vue');

export default [{
    path: '/machineSubsidy',
    name: 'machineSubsidy',
    component: machineSubsidy,
    meta: {
        title: '农机补贴'
    }
}, {
    path: '/machineSubsidyDetail',
    name: 'machineSubsidyDetail',
    component: machineSubsidyDetail,
    meta: {
        title: '农机补贴详情',
        notRequireAuthor: true
    }
}
];