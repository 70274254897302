/** ********** 品种权检索 ********** */
export default {
  varietyList: {
    api: '/api/variety/retrieval',
    name: '品种权列表'
  },
  varietyDetail: {
    api: '/api/variety/info/',
    name: '品种权详情'
  }
};
