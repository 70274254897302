const adminLogin = () => import('@/pages/admin/admin.vue');
export default [{
    path: '/admin',
    name: 'adminlogin',
    component: adminLogin,
    meta: {
        login: true,
        title: "账号管理",
        notRequireAuthor: true
    }
}];