const dutyManagement = () => import('@/pages/dutyManagement/index.vue');
const approvalManagement = () => import('@/pages/dutyManagement/approvalManagement.vue');
const myApplication = () => import('@/pages/dutyManagement/myApplication.vue');
const dutyStatistics = () => import('@/pages/dutyManagement/dutyStatistics.vue');

export default [
  // {
  //   path: '/dutyManagement',
  //   name: 'dutyManagement',
  //   redirect: '/dutyManagement/approvalManagement',
  //   component: dutyManagement,
  //   meta: {
  //     title: '勤务管理'
  //   },
  //   children: [
  //     {
  //         path: "approvalManagement",
  //         name: "审批管理",
  //         component: approvalManagement
  //     },
  //     {
  //         path: "myApplication",
  //         name: "我的申请",
  //         component: myApplication
  //     },
  //     {
  //         path: "dutyStatistics",
  //         name: "统计报表",
  //         component: dutyStatistics
  //     }
  //   ]
  // }

  {
    path: '/approvalManagement',
    name: 'approvalManagement',
    meta: {
      title: '审批管理'
    },
    component: approvalManagement
  },
  {
    path: '/myApplication',
    name: 'myApplication',
    meta: {
      title: '我的申请'
    },
    component: myApplication
  },
];
