const issuingApply = () => import('@/pages/issuingApply/index.vue');
const upcomingApply = () => import('@/pages/issuingApply/upcomingApply.vue');
const allApply = () => import('@/pages/issuingApply/allApply.vue');

export default [
  // {
  //   path: '/issuingApply',
  //   name: 'issuingApply',
  //   redirect: '/issuingApply/upcomingApply',
  //   component: issuingApply,
  //   meta: {
  //     title: '开证申请'
  //   },
  //   children: [
  //     {
  //         path: "upcomingApply",
  //         name: "待办申请",
  //         component: upcomingApply
  //     },
  //     {
  //         path: "allApply",
  //         name: "全部申请",
  //         component: allApply
  //     }
  //   ]
  // },
  {
    path: '/upcomingApply',
    name: 'upcomingApply',
    meta: {
      title: '待办申请'
    },
    component: upcomingApply
  },
  {
    path: '/allApply',
    name: 'allApply',
    meta: {
      title: '全部申请'
    },
    component: allApply
  },
];
