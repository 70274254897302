const myApproval = () => {
  return import('@/pages/myApproval/index.vue');
};

const routers = [
  {
    path: '/myApproval',
    name: 'myApproval',
    component: myApproval,
    meta: {
      title: '我的审批'
    }
  }
];

export default routers;
