var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-top"},[_c('div',{staticClass:"wrap"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require('@/assets/images/qrCode/logo.png'),"alt":""}})]),_vm._m(0),_c('div',{staticClass:"search"},[_c('p'),(_vm.isLogin)?_c('p',{staticClass:"signOut"},[_c('span',{staticClass:"userNameClass"},[_vm._v("用户："+_vm._s(_vm.userName.phoneNumber))]),_c('span',{on:{"click":_vm.handleLogout}},[_vm._v("退出登录")])]):_c('p',[_c('router-link',{attrs:{"to":{
            name: 'adminlogin',
            query: {
              index: 1
            }
          }}},[_vm._v("您好！ 登录 ")])],1)])]),_c('el-amap',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"plugin":_vm.plugin}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search"},[_c('div',{staticClass:"search_input",staticStyle:{"margin-top":"20px"}})])
}]

export { render, staticRenderFns }