// 基地成果
const baseAchievement = () =>
  import('@/pages/property/baseAchievement/baseAchievement');
const baseList = () => import('@/pages/property/baseAchievement/baseList');
// 专利评估
const patentAssess = () => import('@/pages/property/patentAssess/patentAssess');
// 专利维权
const patentRights = () => import('@/pages/property/patentRights/patentRights');
// 专利机器人
const patentRobot = () => import('@/pages/property/patentRobot/patentRobot');
// 政策匹配
const policyMatch = () => import('@/pages/property/policyMatch/policyMatch');
// 专利检索
const patentSearch = () => import('@/pages/property/patentSearch/patentSearch');
const patentList = () => import('@/pages/property/patentSearch/patentList');
const patentDetail = () => import('@/pages/property/patentSearch/patentDetail');

export default [
  {
    path: '/baseAchievement',
    name: 'baseAchievement',
    component: baseAchievement,
    meta: { title: '基地成果' }
  },
  {
    path: '/baseList',
    name: 'baseList',
    component: baseList,
    meta: { title: '基地列表' }
  },
  {
    path: '/patentAssess',
    name: 'patentAssess',
    component: patentAssess,
    meta: { title: '专利评估' }
  },
  {
    path: '/patentRights',
    name: 'patentRights',
    component: patentRights,
    meta: { title: '专利维权' }
  },
  {
    path: '/patentRobot',
    name: 'patentRobot',
    component: patentRobot,
    meta: { title: '专利机器人' }
  },
  {
    path: '/policyMatch',
    name: 'policyMatch',
    component: policyMatch,
    meta: { title: '政策匹配' }
  },
  {
    path: '/patentSearch',
    name: 'patentSearch',
    component: patentSearch,
    meta: { title: '专利检索' }
  },
  {
    path: '/patentSearch/patentList',
    name: 'patentList',
    component: patentList,
    meta: { title: '专利列表' }
  },
  {
    path: '/patentSearch/patentDetail',
    name: 'patentDetail',
    component: patentDetail,
    meta: { title: '专利详情' }
  }
];
