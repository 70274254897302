// 成果转化
const nanfanCar = () => import('@/pages/nanfanCar/index');

export default [
  {
    path: '/nanfanCar',
    name: 'nanfanCar',
    component: nanfanCar,
    meta: { title: '南繁用车' }
  }
];
