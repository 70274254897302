const baseProfiles = {
  baseDetailsContent: {
    api: '/api/baseprofile/selectDetailById',
    name: '基地管理- 基地详情'
  },
  baseBreeding: {
    api: '/api/baseprofile/queryBaseBreedForEchart',
    name: '基地管理- 基地详情 - 基地育种信息'
  },
  basePersonnel: {
    api: '/api/keypersonnel/queryBasePersonForChart',
    name: '基地管理- 基地详情 - 基地人员分布'
  },
  basePerson_sum: {
    api: '/api/keypersonnel/queryCountByCity',
    name: '基地管理- 首页 - 查询基地人员数量'
  },
  baseResults: {
    api: '/api/baseprofile/queryPayoffsForEchart',
    name: '基地管理- 基地详情 - 基地成果一览'
  },
  addSubmit: {
    api: '/api/baseprofile/insert/baseApplyVisitRecord',
    name: '基地管理- 提交基地申请'
  },
  baseReality: {
    api: '/api/baseprofile/queryBaseLive',
    name: '基地管理- 基地实况内容'
  },
  queryIsRecord: {
    api: '/api/baseprofile/queryIsRecord',
    name: '基地管理- 判断用户是否备案'
  },
  baseApplyVisitStatus: {
    api: '/api/baseprofile/find/baseApplyVisitStatus',
    name: '基地管理- 判断用户跳转详情还是申请或者是已经申请'
  },
  upload_img_video: {
    api: '/api/baseprofile/base_equipment_url/insert',
    name: '基地管理- 上传图片或者视频'
  },
  baseScreeningContent: {
    api: '/api/baseprofile/find/baseDetailList',
    name: '基地管理- 基地查询显示全部基地'
  },
  cityList: {
    api: '/api/baseprofile/find/cityList',
    name: '基地管理- 首页 - 基地城市内容'
  },
  getCityList: {
    api: '/api/member/queryAreaByCon',
    name: '基地管理- 基地选择 - 获取市-区-乡数据'
  },
  baseSearchList: {
    api: '/api/baseprofile/find/baseDetailList',
    name: '基地管理- 基地选择 -  获取三个层级数据查找'
  },
  base_img_list: {
    api: '/api/baseprofile/queryAllBaseBanner',
    name: '基地管理- 基地选择 - 获取所有基地banner图'
  },
  baseFindAchievementsById: { // ok
    api: '/api/baseprofile/findAchievementsById',
    name: '基地管理- 基地详情 - 根据id获取成果详情'
  },
  baseFindBaseBredVarietyById: { // ok
    api: '/api/baseprofile/findBaseBredVarietyById',
    name: '基地管理- 基地详情 - 查询育成品种详情'
  },
  baseFindByLevel: {
    api: '/api/baseprofile/findByLevel',
    name: '基地管理- 基地详情 - 根据级别返回成果一览'
  },
  baseQueryAllBaseBredVariety: { // ok
    api: '/api/baseprofile/queryAllBaseBredVariety',
    name: '基地管理- 基地详情 - 获取育成品种列表'
  },
  baseQueryBaseAchievements: { // ok
    api: '/api/baseprofile/queryBaseAchievements',
    name: '基地管理- 基地详情 - 获取基地成果一览'
  },
  baseQueryBaseApplyByUserBaseId: {
    api: '/api/baseprofile/queryBaseApplyByUserBaseId',
    name: '基地管理- 基地详情 - 查询用户申请基地详情'
  },
  baseQueryBaseProfileInfo: {
    api: '/api/baseprofile/queryBaseProfileInfo',
    name: '基地管理- 基地详情 - 根据条件查询基地信息'
  },
  baseQueryBredVarietyTotalCount: { // ok
    api: '/api/baseprofile/queryBredVarietyTotalCount',
    name: '基地管理- 基地详情 - 育成品种个数'
  },
  baseQueryTotal: {
    api: '/api/baseprofile/queryTotal',  // ok
    name: '基地管理- 基地详情 - 获取每个等级下的获奖个数'
  },
  baseQueryExpertListByBaseId: {
    api: '/api/agrotechnical/find/queryExpertListByBaseId',
    name: '基地管理- 基地详情 - 根据基地id查询所有的专家信息'
  },
  baseQueryLaboratory: {
    api: '/api/laboratorys/find/queryLaboratorys',
    name: '基地管理- 基地详情 - 根据基地ID查询实验室列表'
  },
  baseQueryPathentCount: {
    api: '/api/baseprofile/queryPathentCount',
    name: '基地管理- 基地详情 - 获取各专利类别下的数量'
  },
  baseQueryAllBasePatent: {
    api: '/api/baseprofile/queryAllBasePatent',
    name: '基地管理- 基地详情 - 获取所有授权专利数据'
  },
  baseFindBasePatentById: {
    api: '/api/baseprofile/findBasePatentById',
    name: '基地管理- 基地详情 - 获取授权专利详情'
  },
  baseQueryStandardCountt: {
    api: '/api/baseprofile/queryStandardCount',
    name: '基地管理- 基地详情 - 根据基地id获取每个类别下的制定标准个数'
  },
  baseQueryAllBaseStandard: {
    api: '/api/baseprofile/queryAllBaseStandard',
    name: '基地管理- 基地详情 - 获取所有制定标准列表'
  },
  baseQueryStandardById: {
    api: '/api/baseprofile/queryStandardById',
    name: '基地管理- 基地详情 - 获取制定标准详情'
  },
  baseQueryBaseOtherResp: {
    api: '/api/baseprofile/queryBaseOtherResp',
    name: '基地管理- 基地详情 - 根据基地id获取其他展示数据'
  },
  baseQueryIndustryResp: {
    api: '/api/baseprofile/queryIndustryResp',
    name: '基地管理- 基地详情 - 根据基地id获取产业发展数据   '
  },
  baseMember: {
    api: '/api/member/user-info',
    name: '当前登陆用户详细信息'
  },
  baseInsertBaseSuperApply: {
    api: '/api/baseprofile/insertBaseSuperApply',
    name: '超级用户申请'
  },
  baseQueryAllBaseApply: {
    api: '/api/baseprofile/queryAllBaseApply',
    name: '分页查询基地申请记录'
  },
  baseQueryAllSuperApply: {
    api: '/api/baseprofile/queryAllSuperApply',
    name: '分页查询超级用户申请列表'
  },
  baseQuerySuperApplyById: {
    api: '/api/baseprofile/querySuperApplyById',
    name: '根据id查询超级用户申请详情'
  },
  baseGetNanFanSurvey: {
    api: '/api/baseprofile/getNanFanSurvey',
    name: '【报表】获取南繁概况数据'
  },
  baseQueryCoreBaseIndustry: {
    api: '/api/baseprofile/queryCoreBaseIndustry',
    name: '基地报表 - 基地特色'
  },
  baseQueryCoreCountForEchart: {
    api: '/api/baseprofile/queryCoreCountForEchart',
    name: '基地报表 - 南繁基地'
  },
  baseQueryTotalByYear: {
    api: '/api/baseprofile/queryAllOverviewData',
    name: '基地报表 - 获取所有每个等级下的获奖个数 - 南繁成果所有数据'
  },
  baseQueryBredTotalByYear: {
    api: '/api/baseprofile/queryAllVarietyCount',
    name: '基地报表 - 获取所有育成品种总数量'
  },
  baseQueryVarietyByMonth: {
    api: '/api/baseprofile/queryVarietyByMonth',
    name: '基地报表 - 获取指定年份某个月份的育成品种数据'
  },
  baseQueryPatentCountByYear: {
    api: '/api/baseprofile/queryPatentCountByYear',
    name: '基地报表 - 获取截止到xxx年各专利类别下的数量'
  },
  baseQueryStandardCountByYear: {
    api: '/api/baseprofile/queryStandardCountByYear',
    name: '基地报表 - 获取截止到xxx年每个类别下的标准'
  },
  baseLevelExperts: {
    api: '/api/agrotechnical/find/levelExperts',
    name: '基地报表 - 专家等级分类个数'
  },
  baseQueryExpertList: {
    api: '/api/agrotechnical/open/query-expert-list',
    name: '基地报表 - 专家/人才列表'
  },
  baseGetPersonDataByYear: {
    api: '/api/keypersonnel/getPersonDataByYear',
    name: '基地报表 - 获取基地人员分布数据'
  },
  baseQueryBaseByCompanyId: {
    api: '/api/baseprofile/queryBaseByCompanyId',
    name: '基地管理 - 获取指定单位下的基地数据'
  },
  baseQueryBaseOverviewText: {
    api: '/api/baseprofile/queryBaseOverviewText',
    name: '基地管理 - 获取基地概览文本信息'
  },
  baseQueryRoleCountByBaseId: {
    api: '/api/baseprofile/queryRoleCountByBaseId',
    name: '基地管理 - 获取指定基地人员分布数据'
  },
  baseQueryRoleCountForChart: {
    api: '/api/baseprofile/queryRoleCountForChart',
    name: '基地管理 - 获取基地总人数echart-先备案人员写死'
  },
  baseDeductIntegralOfDataId: {
    api: '/api/share/deductIntegralOfDataId',
    name: '基地管理 - 查看数据扣除积分'
  },
  baseQueryRoleCountBySubGroup: {
    api: '/api/baseprofile/queryRoleCountBySubGroup',
    name: '基地列表 - 获取基地人员数量接口'
  }
};

export default baseProfiles;
