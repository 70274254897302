 const machineSubsidy = {
    /** ********** 补贴申请 - 模块接口 ********** */
    subsidyQueryScreening: {
        api: '/api/subsidy/find/machine/type',
        name: '补贴查询-筛选'
    },
    subsidyEnquiryDetails: {
        api: '/api/subsidy/find/machine/',
        name: '补贴查询-详情'
    },
    subsidyEnquiryHomepage: {
        api: '/api/subsidy/find/machineList/page',
        name: '补贴列表'
    }
      
};
  
export default machineSubsidy;
  