const nanfanNews = () => import('@/pages/nanfanNews/nanfanNews.vue');
const newsList = () => import('@/pages/nanfanNews/newsList.vue');
export default [{
    path: '/nanfanNews',
    name: 'nanfanNews',
    component: nanfanNews,
    meta: {
        title: '南繁新闻',
        notRequireAuthor: true
    }
}, {
    path: '/newsList',
    name: 'newsList',
    component: newsList,
    meta: {
        title: '新闻列表',
        notRequireAuthor: true
    }
}];