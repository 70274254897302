export default {
    /** ********** 交易市场 - 模块接口 ********** */
    // marketfirstList: {
    //     api: '/api/trademarket/v1/product-type/find-menu',
    //     name: '交易市场——一级菜单'
    // },
    marketfirstList: {
        api: '/api/mall/goods-category/find-menu',
        name: '交易市场——一级菜单'
    },
    marketSecondList: {
        api: '/api/mall/goods-category/find-menu/',
        name: '交易市场——二三级菜单'
    },
    // marketSecondList: {
    //     api: '/api/trademarket/v1/product-type/find-menu/',
    //     name: '交易市场——二三级菜单'
    // },
    marketHotList: {
        api: '/api/trademarket/v1/product-type/hot-recommend-menu/',
        name: '交易市场——热门菜单'
    },
    // marketRandomList: {
    //     api: '/api/trademarket/v1/product/random',
    //     name: '交易市场——随机商品'
    // },
    marketHotRandomList: {
        api: '/api/mall/goods-category/hot-recommend-menu/',
        name: '交易市场——热门推荐'
    },
    marketRandomList: {
        api: '/api/mall/goods/find-page',
        name: '交易市场——热门推荐'
    },
    marketPageList: {
        api: '/api/mall/goods/find-page',
        name: '交易市场——分页查询'
    },
    marketGoodsDetailList: {
        api: '/api/mall/goods/',
        name: '交易市场——分页查询'
    },
    // marketPageList: {
    //     api: '/api/trademarket/v1/product/find-page',
    //     name: '交易市场——分页查询'
    // },
    marketDetailList: {
        api: '/api/trademarket/v1/product/',
        name: '交易市场详情'
    },
    queryMachineTransDetailList: {
        api: '/api/machinetransfer/v1/query-machine-transfer-detail/',
        name: '农机交易详情'
    },
    queryMachineTransferList: {
        api: '/api/machinetransfer/v1/query-machine-transfer/list',
        name: '农机交易列表'
    },
    queryLandTransferList: {
        api: '/api/landtransfer/v1/query-land-transfer/list',
        name: '土地租赁列表'
    },
    queryLandTransDetailList: {
        api: '/api/landtransfer/v1/query-land-transfer-detail/',
        name: '土地租赁详情'
    },
    coldChainCarDetail: {
        api: '/api/coldchainvehicle/v1/query-vehicle-transfer-detail/',
        name: '交易市场详情'
    },
    coldChainCarList: {
        api: '/api/coldchainvehicle/v1/query-vehicle-transfer/list',
        name: '发布信息列表（根据用户id和搜索条件查询） 冷链车辆'
    }
    
};