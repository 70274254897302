// 品种权检索
const varietySearch = () => import('@/pages/variety/varietySearch');
const varietyList = () => import('@/pages/variety/varietyList');
const varietyDetail = () => import('@/pages/variety/varietyDetail');

export default [
  {
    path: '/varietySearch',
    name: 'varietySearch',
    component: varietySearch,
    meta: { title: '品种权检索' }
  },
  {
    path: '/varietyList',
    name: 'varietyList',
    component: varietyList,
    meta: { title: '品种权列表' }
  },
  {
    path: '/varietyDetail',
    name: 'varietyDetail',
    component: varietyDetail,
    meta: { title: '品种权详情' }
  }
];
