<template>
  <div>
    <div class="tipsBlock">
<!--      <div class="btnOne btn_outside">-->
<!--        <div class="tipsBlockBox">-->
<!--          <div class="apploadBox">-->
<!--            <el-col class="apploadBox_half apploadBox_border">-->
<!--              &lt;!&ndash; <img src="../../../public/static/img/tipsBlock/ios@2x.png" alt /> &ndash;&gt;-->
<!--              <div class="apploadBox_halfcontain">-->
<!--                <div class="texts">苹果版本</div>-->
<!--                <img-->
<!--                  class="imgs"-->
<!--                  :src="require('@/assets/images/qrCode/ios.png')"-->
<!--                  width="100px"-->
<!--                  height="100px"-->
<!--                />-->
<!--              </div>-->
<!--              <div class="apploadBox_halfcontain">-->
<!--                <div class="texts">安卓版本</div>-->
<!--                <img-->
<!--                  class="imgs"-->
<!--                  :src="require('@/assets/images/qrCode/apk.png')"-->
<!--                  width="100px"-->
<!--                  height="100px"-->
<!--                />-->
<!--              </div>-->
<!--              &lt;!&ndash; <img src="../../../public/static/img/tipsBlock/ios.jpg" width="70px" height="70px" /> &ndash;&gt;-->
<!--            </el-col>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="btn_inside">-->
<!--          <i></i>-->
<!--          <div class="btn_font">手机版</div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="btnTwo btn_outside">
        <div class="btn_inside" @click="toRobot">
          <i></i>
          <div class="btn_font">专利机器人</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    toRobot() {
      if (this.$route.path !== '/patentRobot') {
        this.$router.push({
          path: '/patentRobot',
        })
      }
    },
  },
}
</script>
<style lang="less" scoped>
.tipsBlock {
  width: 80px;
  height: 75px;
  background-color: #fff;
  position: fixed;
  top: 50%;
  right: 0;
  margin-top: -80px;
  box-shadow: 0 1px 10px #9e9e9e;
  z-index: 2000;
  border-radius: 4px 0px 0px 4px;
  .btnOne {
    .tipsBlockBox {
      width: 160px;
      height: 250px;
      position: fixed;
      top: 50%;
      margin-top: -100px;
      right: 100px;
      z-index: 10000;
      background-color: #fff;
      border-radius: 10px;
      display: none;
      box-shadow: rgba(0, 0, 0, 0.15) 0 0 10px;
      .apploadBox {
        width: 100%;
        margin: 20px 0;
        height: 100%;
        .apploadBox_border {
          border-right: 1px solid #fff;
        }
        .apploadBox_half:first-child {
          margin: 0 16px 16px 22px;
        }
        .apploadBox_half {
          height: 100%;
          float: left;
          position: relative;
          text-align: center;
          width: 100%;
          .apploadBox_halfcontain {
            display: flex;
            margin-bottom: 20px;
            align-items: center;
            color: #000;
            .texts {
              width: 20px;
              font-weight: 600;
              color: #3380fe;
              font-size: 14px;
            }
            .imgs {
              margin-left: 5px;
            }
          }

          .apploadBox_font {
            width: 100%;
            color: #333333;
            text-align: center;
            font-size: 12px;
            margin-top: 7px;
          }
        }
      }
    }
    .tipsBlockBox::after {
      content: '';
      border: 10px solid transparent;
      border-left-color: #fff;
      top: 50px;
      right: -20px;
      position: absolute;
      width: 0;
      height: 0;
    }
    .btn_inside i {
      background: url('../../../public/static/img/tipsBlock/phone@2x.png')
        center no-repeat;
      background-size: cover;
      width: 26px;
      height: 26px;
      display: inline-block;
      margin-top: 12px;
    }
  }
  .btnOne:hover {
    background-color: #3380fe;
    color: #fff;
    .tipsBlockBox {
      display: block;
    }
    i {
      background: url('../../../public/static/img/tipsBlock/phone-w@2x.png')
        center no-repeat;
      background-size: cover;
    }
  }
  .btnTwo:hover {
    background-color: #3380fe;
    color: #fff;
    i {
      background: url('../../../public/static/img/tipsBlock/nfjl-w@2x.png')
        center no-repeat;
      background-size: cover;
    }
  }
  .btnTwo i {
    background: url('../../../public/static/img/tipsBlock/nfjl@2x.png') center
      no-repeat;
    background-size: cover;
    width: 26px;
    height: 26px;
    display: inline-block;
    margin-top: 12px;
  }
  .btn_outside {
    width: 80px;
    height: 80px;
    position: relative;
    .btn_inside {
      text-align: center;
      cursor: pointer;
      .btn_font {
        width: 100%;
        height: 20px;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
      }
    }
  }
}
</style>
