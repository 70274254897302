const germplasmDeal = () => import('@/pages/market/germplasmDeal.vue');
const germplasmDealMore = () => import('@/pages/market/germplasmDealMore.vue');
const germplasmDealDetail = () => import('@/pages/market/germplasmDealDetail.vue');
export default [{
    path: '/germplasmDeal',
    name: 'germplasmDeal',
    component: germplasmDeal,
    meta: {
        title: '种质产权交易'
    }
},
{
    path: '/market/germplasmDealMore',
    name: 'germplasmDealMore',
    component: germplasmDealMore,
    meta: {
        title: '种质产权交易'
    }
}, {
    path: '/market/germplasmDealDetail',
    name: 'germplasmDealDetail',
    component: germplasmDealDetail,
    meta: {
        title: '商品详情'
    }
}];