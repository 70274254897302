
const homeNews = () => import("@/pages/home/news/news");
const homeImportance = () => import("@/pages/home/news/plocy");
const homePlocy = () => import("@/pages/home/news/plocy");
const homeNotice = () => import("@/pages/home/news/plocy");
const plocyDetail = () => import("@/pages/home/news/plocydetail");
const index = () => import("@/pages/home/index");
const plantQuarantine = () => import("@/pages/home/plantQuarantine");
const seedDeclaration = () => import("@/pages/home/seedDeclaration");
const searchNews = () => import("@/pages/home/searchNews");
// const home = () => import("@/pages/home/home");
export default [
  {
    path: "/",
    name: "index",
    component: index,
    meta: {
      title: '首页',
      notRequireAuthor: true,
      isHome: true
    }
  },
  // {
  //   path: "/home",
  //   name: "home",
  //   component: home,
  //   meta: {
  //     title: '首页',
  //     notRequireAuthor: true,
  //     isHome: true
  //   }
  // },
  {
    path: "/home/news",
    name: "homenews",
    component: homeNews,
    meta: {
      title: '新闻',
      notRequireAuthor: true
    },
    children: [
      {
        path: "importance",
        name: "newsoption0",
        component: homeImportance,
        meta: {
          title: '海南要闻',
          notRequireAuthor: true
        }
      },
      {
        path: "plocy",
        name: "newsoption1",
        component: homePlocy,
        meta: {
          title: '最新政策',
          notRequireAuthor: true
        }
      },
      {
        path: "notice",
        name: "newsoption2",
        component: homeNotice,
        meta: {
          title: '通知公告',
          notRequireAuthor: true
        }
      },
      {
        path: "detail",
        name: "plocydetail",
        component: plocyDetail,
        meta: {
          title: '详情',
          notRequireAuthor: true
        }
      }
    ]
  },
  {
    path: "/plantQuarantine",
    name: "plantQuarantine",
    component: plantQuarantine,
    meta: {
      title: '植物检疫',
      notRequireAuthor: true
    }
  },
  {
    path: "/seedDeclaration",
    name: "seedDeclaration",
    component: seedDeclaration,
    meta: {
      title: '种子申报',
      notRequireAuthor: true
    }
  },
  {
    path: "/searchNews",
    name: "searchNews",
    component: searchNews,
    meta: {
      title: '新闻搜索',
      notRequireAuthor: true,
      isHome: true
    }
  }
];
