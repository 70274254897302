<template>
  <div class="bottom">
    <div class="bottom_content">
      <span>友情连接：</span>
      <a target="_blank" href="http://www.hainan.gov.cn/szfbgt/">海南省人民政府办公厅</a>
      |
      <a target="_blank" href="http://plan.hainan.gov.cn/">海南省发展和改革委员会</a>
      |
      <a target="_blank" href="http://lr.hainan.gov.cn/">省自然资源和规划厅</a>
      |
      <a target="_blank" href="http://sghw.hainan.gov.cn/">省规划委员会</a>
      |
      <a target="_blank" href="http://dof.hainan.gov.cn/">省海洋与渔业厅</a>
      |
      <a target="_blank" href="http://tourism.hainan.gov.cn/">海南省旅游和文化广电体育厅</a>
      |
      <a target="_blank" href="http://wtt.hainan.gov.cn/swtt/index.html">省文化广电出版体育厅</a>
      |
      <a target="_blank" href="http://hnsthb.hainan.gov.cn/">海南省生态环境厅</a>
      |
      <a target="_blank" href="http://agri.hainan.gov.cn/">海南省农业农村厅</a>
      |
      <a target="_blank" href="http://iitb.hainan.gov.cn/">海南省工业和信息化厅</a>
      |
      <a target="_blank" href="http://dofcom.hainan.gov.cn">海南省商务厅</a>
      |
      <a target="_blank" href="http://dost.hainan.gov.cn/">海南省科学技术厅</a>
      |
      <a target="_blank" href="http://mof.hainan.gov.cn/czt/">海南省财政厅</a>
      |
      <a target="_blank" href="http://hrss.hainan.gov.cn/index.html">海南省人力资源和社会保障厅</a>
      |
      <a target="_blank" href="http://edu.hainan.gov.cn/">海南省教育厅</a>
      |
      <a target="_blank" href="http://wst.hainan.gov.cn/">海南省卫生健康委员会</a>
      |
      <a target="_blank" href="http://ga.hainan.gov.cn/">海南省公安厅</a>
      |
      <a target="_blank" href="http://justice.hainan.gov.cn/">省司法厅</a>
      |
      <a target="_blank" href="http://www.hainan.gov.cn/szfbgt/hnfzb/">海南省法制办公室</a>
      |
      <a target="_blank" href="http://mz.hainan.gov.cn/">海南省民政厅</a>
      |
      <a target="_blank" href="http://smzw.hainan.gov.cn/">海南省民族宗教事务委员会</a>
      |
      <a target="_blank" href="http://zjt.hainan.gov.cn/">海南省住房和城乡建设厅</a>
      |
      <a target="_blank" href="http://www.hainanjt.gov.cn/">海南省交通运输厅</a>
      |
      <a target="_blank" href="http://audit.hainan.gov.cn/">海南省审计厅</a>
      |
      <a target="_blank" href="http://dfoca.hainan.gov.cn/">海南省外事办公室</a>
      |
      <a target="_blank" href="http://swt.hainan.gov.cn/">海南省水务厅</a>
      |
      <a target="_blank" href="http://ajj.hainan.gov.cn/">海南省应急管理厅</a>
      |
      <a target="_blank" href="http://gzw.hainan.gov.cn/sgzw/">海南省国有资产监督管理委员会</a>
      |
      <a target="_blank" href="http://www.hainan.gov.cn/szfbgt/jrb/">海南省政府金融工作办公室</a>
      |
      <a target="_blank" href="http://www.hainan.gov.cn/">海南省市场监督管理局</a>
      |
      <a target="_blank" href="http://aic.hainan.gov.cn/">省工商行政管理局</a>
      |
      <a target="_blank" href="http://qtsb.hainan.gov.cn/">省质量技术监督局</a>
      |
      <a target="_blank" href="http://hifda.hainan.gov.cn/">省食品药品监督管理局</a>
      |
      <a target="_blank" href="http://wj.hainan.gov.cn/">省物价局</a>
      |
      <a target="_blank" href="http://stats.hainan.gov.cn/">海南省统计局</a>
      |
      <a target="_blank" href="http://lyt.hainan.gov.cn/">海南省林业局</a>
      |
      <a target="_blank" href="http://fpb.hainan.gov.cn/">海南省扶贫工作办公室</a>
      |
      <a target="_blank" href="http://yangpu.hainan.gov.cn/yangpu/">洋浦经济开发区</a>
      |
      <a target="_blank" href="http://zw.hainan.gov.cn//2rd/">海南省人民政府政务服务中心</a>
      |
      <a target="_blank" href="http://geo.hainan.gov.cn/hngeo/">海南省地质局</a>
      |
      <a target="_blank" href="http://www.nanhai.org.cn/">中国南海研究院</a>
      |
      <a target="_blank" href="http://gxs.hainan.gov.cn/">海南省供销合作联社</a>
      |
      <a target="_blank" href="http://www.hngjj.net/">海南省住房公积金管理局</a>
      |
      <a target="_blank" href="http://archives.hainan.gov.cn/web/default/index.jsp">海南省档案局(馆)</a>
      |
      <a target="_blank" href="http://ea.hainan.gov.cn">海南省考试局</a>
    </div>
    <div class="Copyright">
      <!-- 系统开发公司：北京派得伟业科技发展有限公司 |  -->
      <p>海南省南繁管理局 服务热线：400-921-5009 (个人/企业)</p>
      <!-- <p>技术支持 © 1996-{{ cur_year }} 北京派得伟业科技发展有限公司 | 科众三六五科技有限公司 服务热线：400-921-5009 (个人/企业)</p> -->
      <p>
        <a href="http://beian.miit.gov.cn" target="_blank">琼ICP备2020003619号</a>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'public-bottom',
  created() {
    const myDate = new Date();

    this.cur_year = myDate.getFullYear();
  },
  data() {
    return {
      cur_year: '' // 当前年份
    };
  },
};
</script>
<style lang="less" scoped>
.bottom {
  width: 100%;
  height: auto;
  background-color: #3380fe;
  padding: 30px 0;
  box-sizing: border-box;
  .bottom_content {
    width: 95%;
    margin: 0 auto;
    font-family: MicrosoftYaHei-Bold;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 30px;
    letter-spacing: 1px;
    color: #ffffff;
    margin-bottom: 20px;
  }
  .Copyright {
    p {
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      font-family: "Microsoft YaHei";
      font-size: 12px;
    }
  }
}
</style>
