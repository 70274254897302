const publicService = {
    queryRecruit: {
        api: "/api/recruitment/query-position-all",
        name: "查询招聘列表"
    },
    queryPosition: {
        api: "/api/recruitment/query-beg-position-all",
        name: "查询求职列表"
    },
    innovationServeInfo: {
        api: '/api/acceptance/find/serveInfoAppDataAppList',
        name: '机构服务-分页查询服务列表'
    },
    equipmentSelectById: {
        api: '/api/equipment/open/selectById',
        name: '机构服务-分页查询服务列表'
    },
    innovationServeDetail: {
        api: '/api/acceptance/find/serveInfoDetail/',
        name: '机构服务-查询各类服务的详情'
    },
    innovationServeList: {
        api: '/api/acceptance/find/applyServeList',
        name: '机构服务-查询服务的评论列表'
    },
    getResumeDetail: {
        api: "/api/recruitment/query-beg-details/",
        name: "查询求职详情"
    },
    getRecruitDetail: {
        api: "/api/recruitment/query-position-details/",
        name: "查询招聘详情"
    }
  
};
export default publicService;
  