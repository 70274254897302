import Vue from 'vue';
import VueRouter from 'vue-router';
// 首页
import home from './modules/home';
// 后台
import admin from './modules/admin';
// 知识产权
import property from './modules/property';
// 共享发布
import dataShare from './modules/dataShare';
// 南繁管理
import nanFanManagement from './modules/nanFanManagement';
// 南繁服务
import publicService from './modules/publicService';
// 专家人才
import expertTalent from './modules/expertTalent';
// 化肥农药交易
import agriculturalAssets from './modules/agriculturalAssets';
//种质交易
import germplasmDeal from './modules/germplasmDeal';
//农机交易
import farmingMachinery from './modules/farmingMachinery';
//土地租赁
import landLeasing from './modules/landLeasing';
//冷链车辆
import coldChainCar from './modules/coldChainCar';
// 种质资源
import germplasmResource from './modules/germplasmResource';
// 农机补贴
import machineSubsidy from './modules/machineSubsidy';
// 病害虫数据
import pestData from './modules/pestData';
// 南繁新闻
import nanfanNews from './modules/nanfanNews';

// 二维码
//南繁备案
import nfRecord from './modules/nfRecord';
// 补贴政策
import policyMatching from './modules/policyMatching';
// 成果转化
import achievement from './modules/achievement';
// 品种权检索
import variety from './modules/variety';
// 南繁备案
import nanfanRecords from './modules/nanfanRecords';
// 补贴申请
import subsidyDeclare from './modules/subsidyDeclare';
//
import aboutNanfan from './modules/aboutNanfan';
// 移动办公
import officialDocument from './modules/officialDocument';
// 南烦登记
import nanfanRegister from './modules/nanfanregister';
// 转基因申报
import transgenicRegistration from './modules/transgenicRegistration';
// 实验室信息
import laboratoryInfo from './modules/laboratoryInfo';
// 勤务管理
import dutyManagement from './modules/dutyManagement';
// 开证申请
import issuingApply from './modules/issuingApply';
// OA我的审批.md
import myApproval from './modules/myApproval';
// 南繁用车
import nanfanCar from './modules/nanfanCar';
const downLoad = () => import('@/pages/qrCode/index.vue');

Vue.use(VueRouter);
// 不需要登录的组件,meta里面加notRequireAuthor:true

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
const routes = [
  {
    path: '/index',
    redirect: {
      name: 'index'
    }
  },
  {
    path: '/downLoad',
    name: 'downLoad',
    component: downLoad,
    meta: { title: '下载APP', notRequireAuthor: true }
  },
  ...myApproval,
  ...home,
  ...admin,
  ...property,
  ...dataShare,
  ...nanFanManagement,
  ...publicService,
  ...expertTalent,
  ...agriculturalAssets,
  ...germplasmDeal,
  ...farmingMachinery,
  ...landLeasing,
  ...coldChainCar,
  ...germplasmResource,
  ...machineSubsidy,
  ...pestData,
  ...nfRecord,
  ...policyMatching,
  ...achievement,
  ...variety,
  ...nanfanNews,
  ...nanfanRecords,
  ...subsidyDeclare,
  ...aboutNanfan,
  ...officialDocument,
  ...nanfanRegister,
  ...transgenicRegistration,
  ...laboratoryInfo,
  ...dutyManagement,
  ...issuingApply,
  ...nanfanCar
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
