const pestData = {
    kindList: {
        api: '/api/diseases/app/query/classification/big',
        name: '首页分类'
      },
      popupList: {
        api: '/api/diseases/app/query/classification/midAndSmall',
        name: '筛选分类'
      },
      detail: {
        api: '/api/diseases/app/query/detail/',
        name: '筛选详情'
      },
      smallList: {
        api: '/api/diseases/app/query/list/small',
        name: '病虫害详细列表'
      },
      homeList: {
        api: '/api/diseases/app/query/home/list',
        name: '首页列表'
      }
};
  
export default pestData;
  