export default {
  issuingApplyList: {
    api: '/api/credential/v1/backend/find-page',
    name: '全部列表'
  },
  issuingApplyListApply: {
    api: '/api/credential/v1/backend/find-approval-page',
    name: '代办列表'
  },
  issuingApplyDetail: {
    api: '/api/credential/v1/frontend/',
    name: '代办详情'
  },
  issuingApplyisOK: {
    api: '/api/credential/v1/backend/audit',
    name: '审批'
  },
  issuingApplyDownloadPdf: {
    api: '/api/credential/v1/backend/',
    name: '审批'
  },
  department: {
    api: '/api/credential/v1/frontend/department',
    name: '获取审批信息'
  }
};

// 开证申请
