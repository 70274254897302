/*
 * @Title: ****
 * @Date: 2020-08-24 13:59:36
 * @FilePath: /nanfan-portal/src/router/modules/officialDocument.js
 */
const officialDocument = () => import('@/pages/officialDocument/index.vue');
const proposedText = () => import('@/pages/officialDocument/proposedText.vue');
const documentText = () => import('@/pages/officialDocument/documentText.vue');
const awaitDocumentText = () => import('@/pages/officialDocument/awaitDocumentText.vue');
const formDetails = () => import('@/pages/officialDocument/formDetails.vue');
const allOfficial = () => import('@/pages/officialDocument/allOfficial.vue');
const assign = () => import('@/pages/officialDocument/assign.vue');
// 文档管理
const documentManagement = () => import('@/pages/documentManagement/index.vue');

export default [
  {
    path: '/awaitDocumentText',
    name: 'awaitDocumentText',
    component: awaitDocumentText,
    meta: {
      title: '待办管理'
    },
    // children: [
    //   {
    //       path: "proposedText",
    //       name: "拟文管理",
    //       component: proposedText
    //   },
    //   {
    //       path: "awaitDocumentText",
    //       name: "待办管理",
    //       component: awaitDocumentText
    //   },
    //   {
    //       path: "documentText",
    //       name: "办文管理",
    //       component: documentText
    //   },
    //   {
    //     path: "allOfficial",
    //     name: "全部公文",
    //     component: allOfficial
    //   },
    //   {
    //     path: "assign",
    //     name: "指派管理",
    //     component: assign
    //   }
    // ]
  },

  {
    path: '/documentText',
    name: 'documentText',
    meta: {
      title: '办文管理'
    },
    component: documentText
  },
  {
    path: '/proposedText',
    name: 'proposedText',
    meta: {
      title: '拟文管理'
    },
    component: proposedText
  },
  {
    path: '/allOfficial',
    name: 'allOfficial',
    meta: {
      title: '全部公文'
    },
    component: allOfficial
  },
  {
    path: '/assign',
    name: 'assign',
    meta: {
      title: '指派管理'
    },
    component: assign
  },
  {
    path: '/formDetails',
    name: 'formDetails',
    meta: {
      title: '呈批单'
    },
    component: formDetails
  },
  {
    path: '/documentManagement',
    name: 'documentManagement',
    component: documentManagement,
    meta: { title: '文档管理' }
  }
];
