export default {
  /** ********** 基地成果 - 模块接口 ********** */
  baseArea: {
    api: '/api/baseprofile/find/cityList',
    name: '基地区域'
  },
  baseList: {
    api: '/api/baseprofile/find/baseDetailList',
    name: '基地列表'
  },
  /** ********** 专利检索 - 模块接口 ********** */
  patentList: {
    api: '/api/patent/open/search/word',
    name: '查询专利列表'
  },
  patentDetail: {
    api: '/api/patent/open/get?id=',
    name: '专利详情'
  },
  patentClaim: {
    api: '/api/patent/open/get/claim?id=',
    name: '查询专利权利'
  },
  getFamily: {
    api: '/api/patent/open/get/family',
    name: '查询专利族'
  },
  simliarPatent: {
    api: '/api/patent/open/simliar',
    name: '相似专利'
  },
  wordcloud: {
    api: '/api/patent/open/get/words',
    name: '词云图 - 详情'
  },
  /** ********** 专利评估 - 模块接口 ********** */
  patentValue: {
    api: '/api/patent/open/valuation',
    name: '专利价值查询'
  },
  /** ********** 专利维权 - 模块接口 ********** */
  patentRights: {
    api: '/api/patent/patent_rights_protection/insert',
    name: '专利维权'
  },
  /** ********** 专利机器人 - 模块接口 ********** */
  robotInit: {
    api: '/api/robot/getWelcomeSpeech',
    name: '专利机器人-初始'
  },
  robotAnswer: {
    api: '/api/robot/selectQuestionListOfInput',
    name: '专利机器人-问题回答'
  }
};
