const policyMatching = () => import('@/pages/policyMatching/index.vue');
const policyMatchingDetail = () => import('@/pages/policyMatching/detail.vue');

export default [{
    path: '/policyMatching',
    name: 'policyMatching',
    component: policyMatching,
    meta: {
        title: '补贴政策'
    }
}, {
    path: '/policyMatchingDetail',
    name: 'policyMatchingDetail',
    component: policyMatchingDetail,
    meta: {
        title: '补贴政策详情'
    }
}];