import router from '../router';
import configUtils from '@/utils/config';
import store from '../store';
import { message } from 'element-ui';
// 对请求失败的错误统一处理
export const errorHandle = (code, msg) => {
  // tip(code, other)
  console.log(msg);
  // 状态码判断
  switch (code) {
    // 403001: 未登录状态，需要登录
    case '403001':
      localStorage.removeItem(configUtils.baseToken);
      localStorage.removeItem('islogin-status');
      // router.replace({
      //   name: 'adminlogin',
      //   query: {
      //     index: 1
      //   }
      // });
      store.commit('changeLoginStatus', false);
      break;
    // 403002 token过期,清除token并触发原生登录方法
    case '403002':
      localStorage.removeItem(configUtils.baseToken);
      localStorage.removeItem('islogin-status');
      router.replace({
        name: 'adminlogin',
        query: {
          index: 1
        }
      });
      store.commit('changeLoginStatus', false);
      break;
    // 403004: 错误token
    case '403003':
      localStorage.removeItem(configUtils.baseToken);
      localStorage.removeItem('islogin-status');
      router.replace({
        name: 'adminlogin',
        query: {
          index: 1
        }
      });
      store.commit('changeLoginStatus', false);
    break;
    case '403004':
      localStorage.removeItem(configUtils.baseToken);
      localStorage.removeItem('islogin-status');
      break;
    // 被踢
    case '403006':
      localStorage.removeItem(configUtils.baseToken);
      localStorage.removeItem('islogin-status');
      router.replace({
        name: 'home'
      });
      store.commit('changeLoginStatus', false);
      break;
    case '401005':
      break;
    // 404请求不存在
    case 404:
      break;
    default:
      if (msg != '' && msg != undefined){
        message({
          message: msg,
          type: 'warning'
        });
      }
     
  }
};
