const expertTalent = {
  expertTalentList: {
    api: '/api/agrotechnical/open/query-expert-list',
    name: '专家/人才列表'
  },
  expertTalentDetail: {
    api: '/api/agrotechnical/open/query-expert-info/',
    name: '专家人才详情'
  }
};

export default expertTalent;
