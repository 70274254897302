const policyMatching = {
    policyList: {
        api: '/api/subsidy/find/policyList',
        name: '补贴政策-首页'
    },
    policyDetail: {
        api: '/api/subsidy/find/policy/',
        name: '补贴政策-详情'
    }
};
  
export default policyMatching;
  