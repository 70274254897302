const nanfanRegister = () => import('@/pages/nanfanregister/index.vue');
const nanfanRegisterIndex = () => import('@/pages/nanfanregister/components/base.vue');
const nanfanRegisterDetail = () => import('@/pages/nanfanregister/components/detail.vue');
const nanfanInformatMan = () => import('@/pages/nanfanregister/informatManage.vue');
const nanfanStatistics = () => import('@/pages/nanfanregister/statistics.vue');

export default [{
    path: '/nanfanregister',
    name: 'nanfanregister',
    component: nanfanRegister,
    meta: {
        title: '南繁备案'
    }
}, {
    path: '/nanfanregisterindex',
    name: 'nanfanregisterindex',
    component: nanfanRegisterIndex,
    meta: {
        title: '基础信息'
    }
}, {
    path: '/nanfanregisterdetail',
    name: 'nanfanregisterdetail',
    component: nanfanRegisterDetail,
    meta: {
        title: '编辑详情'
    }
}, {
    path: '/nanfanInformatManage',
    name: 'nanfanInformatMan',
    component: nanfanInformatMan,
    meta: {
        title: '登录信息管理'
    }
}, {
    path: '/nanfanStatistics',
    name: 'nanfanStatistics',
    component: nanfanStatistics,
    meta: {
        title: '南繁备案统计'
    }
}];