import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogin: JSON.parse(window.localStorage.getItem('islogin-status')) || false, //控制是否登录
    cache_components: ["nanfanhome"], //缓存组件name
    backlog: '' // 移动办公-待办数量
  },
  mutations: {
    // 增加缓存组件
    addCache(state, components) {
      if (state.cache_components.includes(components)) return;
      state.cache_components.push(components);
    },
    // 删除缓存组件
    removeCache(state, component) {
      if (!state.cache_components.includes(component)) return;
      for (let len = state.cache_components.length, i = len - 1; i >= 0; i--) {
        if (state.cache_components[i] === component) {
          state.cache_components.splice(i, 1);
        }
      }
    },
    // 改变登录状态
    changeLoginStatus(state, bool) {
      state.isLogin = bool;
      window.localStorage.setItem('islogin-status', JSON.stringify(bool));
    },
    // 移动办公-待办数量
    render(state, key) {
      // console.log(state)
      // console.log(key)
      state.backlog = key;
    }
  },
  actions: {},
  modules: {}
});
