const agriculturalAssets = () => import('@/pages/market/agriculturalAssets.vue');
const agriculturalAssetsMore = () => import('@/pages/market/agriculturalAssetsMore.vue');
const agriculturalAssetsDetail = () => import('@/pages/market/agriculturalAssetsDetail.vue');
const dealDetail = () => import('@/pages/market/dealDetail.vue');
export default [{
    path: '/agriculturalAssets',
    name: 'agriculturalAssets',
    component: agriculturalAssets,
    meta: {
        title: '农资销购'
    }
},
{
    path: '/market/agriculturalAssetsDetail',
    name: 'agriculturalAssetsDetail',
    component: agriculturalAssetsDetail,
    meta: {
        title: '农资销购详情'
    }
},
{
    path: '/market/agriculturalAssetsMore',
    name: 'agriculturalAssetsMore',
    component: agriculturalAssetsMore,
    meta: {
        title: '农资销购'
    }
},

{
    path: '/market/dealDetail',
    name: 'dealDetail',
    component: dealDetail,
    meta: {
        title: '商品详情'
    }
}];