const subsidyDeclare = {
    /** ********** 补贴申请 - 模块接口 ********** */
    machineryList: {
        api: '/api/subsidies/subsidy-machines',
        name: '补贴申请-机具列表'
    },
    machineryMessage: {
        api: '/api/subsidies/subsidy-machine-model-info/',
        name: '补贴申请-机具信息'
    },
    machinerySubmit: {
        api: '/api/subsidies/subsidy-apply',
        name: '补贴申请-提交'
    },
    applyList: {
        api: '/api/subsidies/my-subsidy',
        name: '补贴申请-申请列表'
    },
    applyListdel: {
        api: '/api/subsidies/delete/',
        name: '补贴申请-申请删除'
    },
    applyDetails: {
        api: '/api/subsidies/my-subsidy-detail',
        name: '补贴申请-申请详情'
    }
};

export default subsidyDeclare;