export default {
    /** ********** 生物监控 - 模块接口 ********** */
    selectMonitorRoll: {
        api: '/api/monitor/selectMonitorRoll',
        name: '生物监控-滚动信息'
    },
    MonitorType: {
        api: '/api/monitor/selectMonitorSurveyOfMonitorType',
        name: '生物预警'
    },
    selectMonitorSurvey: {
        api: '/api/monitor/selectMonitorSurvey',
        name: '生物监控'
    },
    getKeyProgramAppByPage: {
        api: '/api/keyprogram/getKeyProgramAppByPage',
        name: '重点项目——列表'
    },
    queryPullDownList: {
        api: '/api/keyprogram/queryPullDownList',
        name: '重点项目——地区'
    },
    keyProgramAppById: {
        api: '/api/keyprogram/keyProgramAppById/',
        name: '重点项目——地区'
    },
    queryForEchart: {
        api: '/api/keypersonnel/queryForEchart',
        name: '南繁管理-南繁人员'
    },
    baseDetailList: {
        api: '/api/baseprofile/find/baseDetailList',
        name: '南繁管理-基地风采'
    },
    addProjectList: {
        api: '/api/importantitem/backend/declare/add',
        name: '重点项目-新增项目申报'
    },
    projectIsList: {
        api: '/api/importantitem/backend/item/list',
        name: '重点项目-获取当前用户可申报的项目列表'
    },
    addOrUpdate: {
        api: '/api/importantitem/backend/user/addOrUpdate',
        name: '重点项目-申报账号绑定与修改'
    },
    declareList: {
        api: '/api/importantitem/backend/user/list',
        name: '重点项目-申报账号绑定列表'
    },
    isGetByPhone: {
        api: '/api/importantitem/backend/getByPhone/',
        name: '重点项目-获取输入的手机号是否存在当前'
    },
    declareDetail: {
        api: '/api/importantitem/backend/user/detail/',
        name: '重点项目-申报账号绑定详情'
    },
    declareDelete: {
        api: '/api/importantitem/backend/user/delete/',
        name: '重点项目-删除申报账号绑定'
    },
    declareDeletes: {
        api: '/api/importantitem/backend/declare/delete/',
        name: '重点项目-删除申报'
    },
    PersonalList: {
        api: '/api/importantitem/backend/declare/list',
        name: '重点项目-申报列表-个人'
    },
    statisticalLists: {
        api: '/api/importantitem/backend/declare/statistical/list',
        name: '重点项目-统计分析-项目详情列表'
    },
    declareDetails: {
        api: '/api/importantitem/backend/declare/detail/',
        name: '重点项目-统计分析-申报详情'
    },
    statisticalList: {
        api: '/api/importantitem/backend/statistical/list',
        name: '重点项目-统计分析-总体概况列表'
    },
    getAddressList: {
        api: '/api/region/open/query-region-info/',
        name: '重点项目-获取市区村列表'
    },
    updateDeclare: {
        api: '/api/importantitem/backend/declare/update',
        name: '重点项目-编辑项目上报'
    },
    getStatistical: {
        api: '/api/importantitem/backend/statistical',
        name: '重点项目-编辑项目上报'
    },
    getEcharts: {
        api: '/api/importantitem/backend/statistical2',
        name: '重点项目-申报统计2(当选择所有项目的时候出现echars图)'
    },
    exportExcelFirst: {
        api: '/api/importantitem/backend/exportExcel1',
        name: '重点项目-导出-总体概况1'
    },
    exportExcelSecond: {
        api: '/api/importantitem/backend/exportExcel2',
        name: '重点项目-导出-总体概况2'
    },
    exportExcelThird: {
        api: '/api/importantitem/backend/exportExcel3',
        name: '重点项目-导出-项目详情列表'
    },
    release: {
        api: '/api/importantitem/backend/declare/release/',
        name: '重点项目-项目申报发布'
    },
    projectList: {
        api: '/api/importantitem/backend/class/list',
        name: '重点项目-项目管理-项目列表'
    },
    projectDelete: {
        api: '/api/importantitem/backend/class/delete/',
        name: '重点项目-项目管理-删除项目'
    },
    addProject: {
        api: '/api/importantitem/backend/class/save',
        name: '重点项目-项目管理-新增项目'
    },
    projectDetail: {
        api: '/api/importantitem/backend/class/detail/',
        name: '重点项目-项目管理-项目详情'
    },
    updateProject: {
        api: '/api/importantitem/backend/class/update',
        name: '重点项目-项目管理-修改项目'
    },
};