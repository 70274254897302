// 成果转化
const achievementList = () => import('@/pages/achievement/achievementList');
const achievementDetail = () => import('@/pages/achievement/achievementDetail');

export default [
  {
    path: '/achievementList',
    name: 'achievementList',
    component: achievementList,
    meta: { title: '成果转化' }
  },
  {
    path: '/achievementDetail',
    name: 'achievementDetail',
    component: achievementDetail,
    meta: { title: '成果详情' }
  }
];
