// 南繁备案
const nfRecord = () => import('@/pages/workGuide/nfRecord');

export default [
  {
    path: '/nfRecord',
    name: 'nfRecord',
    component: nfRecord,
    meta: { title: '南繁备案' }
  }
];
