// api 为接口地址，name：为接口描述
import dataShare from './apis/dataShare';
import publicService from './apis/publicService';
import expertTalent from './apis/expertTalent';
import property from './apis/property';
import admin from './apis/admin';
import home from './apis/home';
import germplasmResource from './apis/germplasmResource';
import machineSubsidy from './apis/machineSubsidy';
import pestData from './apis/pestData';
import policyMatching from './apis/policyMatching';
import achievement from './apis/achievement';
import variety from './apis/variety';
import nanFanManagement from './apis/nanFanManagement';
import market from './apis/market';
import nanfanRecords from './apis/nanfanRecords';
import subsidyDeclare from './apis/subsidyDeclare';
import baseProfiles from './apis/baseProfiles';
import bussiness from './apis/bussiness';
import nanfanRegister from './apis/nanfanregister';
import seedEnterprise from './apis/seedEnterprise';
import laboratoryInfos from './apis/laboratoryInfo';
import myApply from './apis/myApplication';
import issuingApply from './apis/issuingApply';
import nanfanCar from './apis/nanfanCar'
const apiList = {
  /** ********** 共用接口 ********** */
  uploadFile: {
    api: '/api/file/open/upload-file',
    name: '文件上传'
  },
  addressInfo: {
    api: '/api/region/open/query-region/5f455bf11fca4fa2b7ab421dc936c57c',
    name: '三级联动地址 - 省数据'
  },
  roleConfirm: {
    api: '/api/member/queryByPhone',
    name: '账号认证'
  },
  getCityList: {
    api: '/api/member/queryAreaByCon',
    name: '基地管理- 基地选择 - 获取市-区-乡数据'
  },
  baseScreeningContent: {
    api: '/api/baseprofile/find/baseDetailList',
    name: '基地管理- 基地查询显示全部基地'
  },
  pollingGetUserInfo: {
    api: '/api/patrol/getUserInfo',
    name: '获取用户登录信息'
  },
  robotIcon: {
    api: '/api/robot/getUserHeadPortrait',
    name: '获取用户登录头像'
  },
  ...myApply,
  ...dataShare,
  ...publicService,
  ...admin,
  ...expertTalent,
  ...property,
  ...home,
  ...germplasmResource,
  ...machineSubsidy,
  ...pestData,
  ...nanFanManagement,
  ...policyMatching,
  ...market,
  ...achievement,
  ...variety,
  ...nanfanRecords,
  ...subsidyDeclare,
  ...baseProfiles,
  ...bussiness,
  ...nanfanRegister,
  ...seedEnterprise,
  ...laboratoryInfos,
  ...issuingApply,
  ...nanfanCar
};

export default apiList;
