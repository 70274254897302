const nanfanRecordsApis = {
  /** ********** 南繁备案 - 模块接口 ********** */
  nanfanRecordSubmit: {
    api: '/api/keeponrecord/insert/record/detail',
    name: '南繁备案 - 提交'
  },
  nanfanRecordDetail: {
    api: '/api/keeponrecord/find/record/detail',
    name: '南繁备案 - 详情获取'
  },
  nanfanMyKeeponrecord: {
    api: '/api/keeponrecord/myKeeponrecord',
    name: '南繁备案 - 我的备案'
  },
  nanfanBaseDetailInfo: {
    api: '/api/baseprofile/record_info/query/',
    name: '根据基地id查询出备案中需要显示的数据'
  },
  nanfanRecordGroup: {
    api: '/api/keeponrecord/find/record/organization',
    name: '备案组别接口'
  },
  nanfanRecordAddBse: {
    api: '/api/baseprofile/record/insert/baseInfo',
    name: '备案 - 基地新增'
  },
  getRecordNature: {
    api: '/api/keeponrecord/find/record/nature',
    name: '根据用户id查看备案类型（0-未备案 1-个人 2-公司）'
  },
  getRoleById: {
    api: '/api/member/getOrganizationRoleById',
    name: '认证管理 - 角色'
  },
  judgeBlackInfo: {
    api: '/api/keypersonnel/judgeBlackInfo',
    name: '黑名单'
  },
  checkModule: {
    api: '/api/keeponrecord/checkModule',
    name: '备案时间'
  },
  getCroplist: {
    api: '/api/keeponrecord/pc/register/getCropNameList',
    name: '获取作物信息'
  }

};

export default nanfanRecordsApis;
