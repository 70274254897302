import ajaxMethod from './ajax';
import configUtils from '@/utils/config';

// function  fetchGet(param, api) {
//   let domainUrl = configUtils.baseurl + api
//   let promise = new Promise((resolve, reject) => {
//     ajaxMethod.get(param, domainUrl).then(res => {
//       if (res) {
//         resolve(res.data)
//       }
//     }).catch(err => {
//       reject(err)
//     })
//   })
//   return promise
// }

async function fetchGet(param, api) {
  const domainUrl = configUtils.baseUrl + api;
  const res = await ajaxMethod.get(param, domainUrl);

  return res;
}

// json: json数据格式，file：文件格式
async function fetchPost(param, api, type = '') {
  const domainUrl = configUtils.baseUrl + api;
  const res = await ajaxMethod.post(param, domainUrl, type);

  return res;

  // let promise = new Promise((resolve, reject) => {
  //   ajaxMethod.post(param, domainUrl).then(res => {
  //     if (res) {
  //       resolve(res)
  //     }
  //   }).catch(err => {
  //     reject(err)
  //   })
  // })
  // return promise
}
export default {
  fetchGet,
  fetchPost
};
