// 南繁备案
const subsidyDeclare = () => import('@/pages/subsidyDeclare/index.vue');

export default [
  {
    path: '/subsidyDeclare',
    name: 'subsidyDeclare',
    component: subsidyDeclare,
    meta: { title: '补贴申请' }
  }
];
