const dataShare = {
    //  病虫害接口
      homeList: {
        api: '/api/diseases/app/query/home/list',
        name: '病虫害类型'
      },
      selectRecordNumOfUser: {
        api: '/api/share/selectRecordNumOfUser',
        name: '查询当前用户共享数据信息'
      },
      selectRecordList: {
        api: '/api/share/selectRecordList',
        name: '查询当前用户共享数据列表信息'
      },
      addPestRecord: {
        api: '/api/share/addPestRecord',
        name: '病害虫共享数据上传'
      },
      addBaseRecord: {
        api: '/api/share/addBaseRecord',
        name: '基地共享数据上传'
      },
      addExpertTalentRecord: {
        api: '/api/share/addExpertTalentRecord',
        name: '专家人才共享数据上传'
      },
      addInstrumentRecord: {
        api: '/api/share/addInstrumentRecord',
        name: '仪器设备共享数据上传'
      },
      addLaboratoryRecord: {
        api: '/api/share/addLaboratoryRecord',
        name: '实验室共享数据上传'
      },
      addMechanismRecord: {
        api: '/api/share/addMechanismRecord',
        name: '服务基地共享数据上传'
      },
      selectById: {
        api: '/api/share/selectById',
        name: '查询共享数据详情'
      },
      updateRecord: {
        api: '/api/share/updateRecord',
        name: '共享数据修改'
      },
      equipmentTypeList: {
        api: '/api/equipment/find/equipmentTypeList',
        name: '产品类型集合'
      },
      classification: {
        api: '/api/diseases/DS/category',
        name: '根据父级id查询子类 0-最顶级父类'
      },
      awardLevel: {
        api: '/api/baseprofile/DS/query/award_level',
        name: '成果一览 -- 查询获奖等级'
      },
      award: {
        api: '/api/baseprofile/DS/query/award',
        name: '成果一览 -- 查询获奖级别/成果奖项'
      },
      awardRank: {
        api: '/api/baseprofile/DS/query/award_rank',
        name: '成果一览 -- 查询排名'
      },
      patentCategory: {
        api: '/api/baseprofile/DS/query/patent_category',
        name: '成果一览 -- 查询成果奖项'
      },
      standardCategory: {
        api: '/api/baseprofile/DS/query/standard_category',
        name: '成果一览 -- 查询成果奖项'
      },
      getRoleTypeList: {
        api: '/api/member/getRoleTypeList',
        name: '查询所有的角色类型'
      },
      instrument: {
        api: '/api/equipment/open/getEquipmentList',
        name: '仪器设备共享-列表'
      },
      laboratory: {
        api: '/api/laboratorys/open/query-laboratorys',
        name: '实验室共享-列表'
      },
      laboratoryDetail: {
        api: '/api/laboratorys/open/query-laboratory/',
        name: '实验室共享-详情'
      },
      selPhone: {
        api: '/api/agrotechnical/backend/selPhone/',
        name: '手机号校验'
      },
      getCityList: {
        api: '/api/member/queryAreaByCon',
        name: '基地管理- 基地选择 - 获取市-区-乡数据'
      }
    };
    export default dataShare;
  