const laboratoryInfo = () => import('@/pages/laboratoryInfo/index.vue');
const sampleManage = () => import('@/pages/laboratoryInfo/sampleManage.vue');
const resourcesManage = () => import('@/pages/laboratoryInfo/resourcesManage.vue');
const statisticalReport = () => import('@/pages/laboratoryInfo/statisticalReport.vue');

export default [
  {
    path: '/laboratoryInfo',
    name: 'laboratoryInfo',
    redirect: '/laboratoryInfo/sampleManage',
    component: laboratoryInfo,
    meta: {
      title: '实验室信息'
    },
    children: [
      {
        path: "sampleManage",
        name: "样品管理",
        component: sampleManage
      },
      {
          path: "resourcesManage",
          name: "资源管理",
          component: resourcesManage
      },
      
      {
          path: "statisticalReport",
          name: "统计报表",
          component: statisticalReport
      }
    ]
  }
];
