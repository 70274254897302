export default {
    nanfanRegisterAdd: {
      api: '/api/keeponrecord/pc/register/insert',
      name: '新增南繁备案'
    },
    nanfanRegisterList: {
      api: '/api/keeponrecord/pc/register/getUserBasisList',
      name: '南繁备案列表'
    },
    nanfanRegisterRemove: {
      api: '/api/keeponrecord/pc/personal_register/delete/',
      name: '南繁备案列表'
    },
    nanfanRegisterDetail: {
      api: '/api/keeponrecord/pc/register/getBasisById/',
      name: '南繁备案详情'
    },
    nanfanRegisterEditor: {
      api: '/api/keeponrecord/pc/register/update',
      name: '编辑南繁备案'
    },
    nanfanRegisterBase: {
      api: '/api/baseprofile/register_base/query_detail/',
      name: '南繁备案基地详情'
    },
  };