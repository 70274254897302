/**
 * @Description:
 * @Author: CHRIS WONG
 * @Date: 2022-05-06 13:42:24
 * @LastEditTime: 2022-05-06 20:24:20
 * @LastEditors: CHRIS WONG
 */
const germplasmApis = {
  /** ********** 种子查询 - 模块接口 ********** */
  germplasmList: {
    api: '/api/germplasm/open/query-by-parentid',
    name: '种质查询',
  },
  germplasmMenu: {
    api: '/api/germplasm/open/category/list',
    name: '种质分类',
  },
  germplasmMenuList: {
    api: '/api/germplasm/open/query-germplasm-list',
    name: '属性查询',
  },
  germplasmMenuDetails: {
    api: '/api/germplasm/open/query-data-details/',
    name: '属性详情',
  },
}

export default germplasmApis
