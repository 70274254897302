const supplementary = {
  supplementarySelect: {
    api: '/api/supplementary/backend/filterConditionList',
    name: '种子企业筛选条件'
  },
  supplementaryDetail: {
    api: '/api/supplementary/backend/seedFirmDetail/',
    name: '种子企业详情'
  },
  supplementaryList: {
    api: '/api/supplementary/backend/seedFirmList',
    name: '种子列表'
  }
};

export default supplementary;
