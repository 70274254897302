/*
 * @Author: jiang
 * @Date: 2021-04-25 11:18:32
 * @LastEditTime: 2021-09-02 14:14:03
 * @LastEditors: Paulus
 * @Description:
 * @FilePath: /src/utils/config.js
 */
/**
 * 根据url判断所处的环境，环境变量
 */
let env = 'QA';
const host = window.location.host;
console.log("host",host)

if (host.includes('fat.nanfan.kechuang.cn')) { // 开发环境
  env = 'QA';
} else if (host.includes('fat-nanfan-portal.kechuang.cn')) { // 开发环境
  env = 'QA';
} else if (host.includes('uat.nanfan.kechuang.cn')) { // 预生产环境
  env = 'UA';
} else if (host.includes('nanfan.kechuang.cn')) { // 生产环境
  env = 'P';
} else if (host.includes('localhost')) { // dev环境
  // env = 'DEV';
  env = 'QA'; // 暂时先fat环境
}

// token
const tokenList = {
  P: 'prd_gettoken',
  QA: 'fat_gettoken',
  UA: 'uat_gettoken',
  DEV: 'dev_gettoken'
};
// 中台token
const kcCloudTokenList = {
  P: 'prd_getKcCloudToken',
  QA: 'fat_getKcCloudToken',
  UA: 'uat_getKcCloudToken',
  DEV: 'dev_getKcCloudToken'
};
// 用户信息
const userInfo = {
  P: 'prd_userInfo',
  QA: 'fat_userInfo',
  UA: 'uat_userInfo',
  DEV: 'dev_userInfo'
};
/**
 * 根据环境变量 来判断请求借口的域名
 */
const baseurlList = {
  P: 'https://nanfanapi.kechuang.cn',
  QA: 'http://fat-nanfan-portal.kechuang.cn',
  // QA: 'https://www.nfggy.com',
  UA: 'https://uat.nanfan.kechuang.cn',
  DEV: 'https://dev.nanfan.kechuang.cn'
};
const baseurlListT = {
  P: 'http://nanfanapi.kechuang.cn',
  QA: 'http://fat-nanfan-portal.kechuang.cn',
  UA: 'http://uat.nanfan.kechuang.cn',
  DEV: 'http://dev.nanfan.kechuang.cn'
};
// 中台域名
const kcCloudUrlList = {
  P: 'https://open.kechuang.cn',
  QA: 'https://fat-open.kechuang.cn',
  UA: 'https://uat-open.kechuang.cn',
  DEV: 'https://dev-open.kechuang.cn'
};
// 中台域名
const kcCloudUrlTList = {
  P: 'http://open.kechuang.cn',
  QA: 'http://fat-open.kechuang.cn',
  UA: 'http://uat-open.kechuang.cn',
  DEV: 'http://dev-open.kechuang.cn'
};

// const baseUrl = baseurlList[env];
const baseUrl = window.location.origin;
// const baseUrl = ''
const baseToken = tokenList[env];
const kcCloudToken = kcCloudTokenList[env];
const kcCloudUrl = kcCloudUrlList[env];
const kcCloudUrlT = kcCloudUrlTList[env];
const baseUserInfo = userInfo[env];
const baseurlAdminUrl  = baseurlListT[env];
export default {
  env,
  baseUrl,
  baseToken,
  kcCloudToken,
  kcCloudUrl,
  kcCloudUrlT,
  baseUserInfo,
  baseurlAdminUrl
};
