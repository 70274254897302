// 南繁备案
const nanfanRecords = () => import('@/pages/nanfanRecords/index.vue');

export default [
  {
    path: '/nanfanRecords',
    name: 'nanfanRecords',
    component: nanfanRecords,
    meta: { title: '南繁备案' }
  }
];
